import React, { useCallback, ReactNode } from "react";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import "./component_file_uploader.less";

interface Props {
  children: ReactNode;
  className?: string;
  style?: any;
  onPost: Function;
  hidden?: boolean;
  isVideo?: boolean;
  multiple?: boolean;
}

const FileUploader: React.FC<Props> = (props) => {
  const {
    children,
    className = "",
    style,
    onPost,
    hidden = false,
    isVideo = false,
    multiple = false,
  } = props;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles || !acceptedFiles[0]) return;

    if (multiple) {
      onPost(acceptedFiles);
    } else {
      onPost(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: isVideo ? "video/*" : "image/*",
    multiple: multiple,
  });

  return (
    <div
      className={classnames("file-uploader", className)}
      {...getRootProps()}
      style={style}
      hidden={hidden}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default FileUploader;
