import { getUserCount } from "../api/Homepage";
import { ISelectOptionItem } from "../components/component_validate_form";

//put appkeys / appsecret ... here
export const AWS_BUCKET = "canvasrecruit-s3";
export const AWS_REGION = "us-west-2"; //Amazon Cognito Region
//put base urls here
export const AWS_URL = "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/";

export const HomepageVideoUrl =
  "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/public-source/canvas_introduction.mp4";

export const SchoolPageVideoUrl =
  "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/canvas/featured_video/Canvas-School.mp4";

export const JpmsVideoUrl =
  "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/public-source/PM_LandingPage_vid.mp4";

export const JpmsVideoImage =
  "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/public-source/Hero_videoimage.jpg";

export const InsApi = "https://www.instagram.com/#/?__a=1";

export const countryOptions: ISelectOptionItem[] = [
  { value: "US", name: "United States" },
];

export const wordPressBlogUrl = "https://www.beautista.com/blog/";
export const facebookUrl = "https://www.facebook.com/beautista.connect/";
export const instagramUrl = "https://www.instagram.com/beautista.connect/";

export const s3BaseUrl = "https://canvasrecruit-s3.s3-us-west-2.amazonaws.com/";
export const scBaseUrl = s3BaseUrl + "public-source/saloncentric/";
export const publicSourceUrl = s3BaseUrl + "public-source/";

export const CANVAS_AUTOSAVE_INTERVAL_MILLISECONDS = 20000;

export const STRIPE_PRICE_ID_EXCLUSIONS = [
  'price_HOHLne5012h1EB',
  'price_HOHJBiPQyobuoJ',
  'price_1GqWxGIWXeIhC4HiE36jx1fs',
];

export const Accounts = {
  USERS: "50,000+",
  SCHOOLS: "250",
}

export const LiveUserCount = async (): Promise<string> => {
  const { count } = await getUserCount() as any;
  const formattedCount = count.toLocaleString();
  return formattedCount;
}

export const CandidateList = [
  {
    option: "OFFERED ACCEPTED",
    key: "offerAccepted",
    btnColor: "#FFFFFF",
    btnBgColor: "#83B23A",
    statusBgColor: "#83B23A",
    statusBorderColor: "rgba(131, 178, 58, 0.25)",
  },
  {
    option: "APPLIED",
    key: "applied",
    btnColor: "rgba(45, 46, 61, 0.5)",
    btnBgColor: "#F1DD23",
    statusBgColor: "#F1DD23",
    statusBorderColor: "rgba(241, 221, 35, 0.25)",
  },
  {
    option: "SCHEDULED INTERVIEW DATE",
    key: "scheduledInterviewDate",
    btnColor: "#FFFFFF",
    btnBgColor: "#FF3535",
    statusBgColor: "#FF3535",
    statusBorderColor: "#EAEAEC",
  },
  {
    option: "BEING SCREENED",
    key: "beingScreened",
    btnColor: "#FFFFFF",
    btnBgColor: "#FFBBC7",
    statusBgColor: "#FFBBC7",
    statusBorderColor: "#EAEAEC",
  },
  {
    option: "CURRENTLY INTERVIEWING",
    key: "interviewing",
    btnColor: "#FFFFFF",
    btnBgColor: "#FF6B00",
    statusBgColor: "#FF6B00",
    statusBorderColor: "rgba(255, 107, 0, 0.1)",
  },
  {
    option: "SCHEDULED  DEMONSTRATION",
    key: "scheduledTechDemonstration",
    btnColor: "#FFFFFF",
    btnBgColor: "#B1DBFF",
    statusBgColor: "#B1DBFF",
    statusBorderColor: "#EAEAEC",
  },
  {
    option: "INTERVIEWED",
    key: "interviewed",
    btnColor: "#FFFFFF",
    btnBgColor: "#3A88E3",
    statusBgColor: "#3A88E3",
    statusBorderColor: "#EAEAEC",
  },
  {
    option: "SUBMITTED TO A HIRING LEADER",
    key: "submittedToHiringLeader",
    btnColor: "#FFFFFF",
    btnBgColor: "#884DE8",
    statusBgColor: "#884DE8",
    statusBorderColor: "rgba(255, 107, 0, 0.1)",
  },
  {
    option: "PENDING QUALIFICATIONS",
    key: "pendingQualifications",
    btnColor: "#594EE9",
    btnBgColor: "#E3E1FB",
    statusBgColor: "#594EE9",
    statusBorderColor: "#E3E1FB",
  },
  {
    option: "OFFER PENDING",
    key: "offerPending",
    btnColor: "#FFFFFF",
    btnBgColor: "#053CFF",
    statusBgColor: "#053CFF",
    statusBorderColor: "#F4F5F5",
  },
  {
    option: "HIRED",
    key: "hired",
    btnColor: "#FFFFFF",
    btnBgColor: "#5A8E09",
    statusBgColor: "#5A8E09",
    statusBorderColor: "rgba(131, 178, 58, 0.5)",
  },
  {
    option: "OFFER DECLINED",
    key: "offerDeclined",
    btnColor: "#96979E",
    btnBgColor: "#EAEAEC",
    statusBgColor: "#96979E",
    statusBorderColor: "#EAEAEC",
  },
  {
    option: "NOT A FIT",
    key: "notAFit",
    btnColor: "#FFFFFF",
    btnBgColor: "#000000",
    statusBgColor: "#000000",
    statusBorderColor: "#EAEAEC",
  },
  {
    option: "WITHDRAWED",
    key: "withdrawed",
    btnColor: "#96979E",
    btnBgColor: "#EAEAEC",
    statusBgColor: "#96979E",
    statusBorderColor: "#EAEAEC",
  },
];

export const ScStoreList = [
  'Vero Beach - 921 14th Ln',
  'Hialeah - 3705 W 20th Ave',
  'Pembroke Pines - 9728 Pines Blvd',
  'Miami - 5743 NW 7th St',
  'N Miami Beach - 1727 NE 163rd St',
  'Miami - 10855 SW 72nd St',
  'Fort Lauderdale - 3304 Griffin Rd',
  'Fort Lauderdale - 1320 SE 17th St',
  'Fort Lauderdale - 4500 N University Dr',
  'Lake Park - 2863 Northlake Blvd',
  'W Palm Beach - 5601 Okeechobee Blvd',
  'Boca Raton - 2621 N Federal Hwy',
  'Deerfield Beach - 1101 S Powerline Rd',
  'Lantana - 6088 S Congress Ave',
  'Sebring - 4141 US Hwy 27 N',
  'Port Charlotte - 4200 Tamiami Trl',
  'Fort Myers - 11770 Metro Pkwy',
  'Naples - 2896 Tamiami Trl E',
  'Bonita Springs - 9174 Bonita Beach Rd SE',
  'Bradenton - 427 Cortez Rd W',
  'Sarasota - 3800 S Tuttle Ave',
  'Sarasota - 5275 University Parkway',
  'Venice - 2210 S Tamiami Trl',
  'Port St Lucie - 10069 S Federal Hwy',
  'Miami - 6629 S. Dixie Hwy',
  'Royal Palm Beach - 543 N State Rd 7',
  'Orange Park - 309 Blanding Blvd',
  'Holly Hill - 1609 N Nova Rd',
  'Port Orange - 3740 Ridgewood Ave',
  'Jacksonville - 10226 Atlantic Blvd',
  'Jacksonville - 9965 San Jose Blvd',
  'Tallahassee - 1848 Thomasville Rd',
  'Panama City - 401 E 23rd St',
  'Pensacola - 5007 N Davis Hwy',
  'Gainesville - 5141 NW 43rd St',
  'Altamonte Springs - 1140 E Altamonte Dr',
  'Apopka - 2459 E Semoran Blvd',
  'Orange City - 2499 Enterprise Rd',
  'Orlando - 5004 E Colonial Dr',
  'Winter Park - 933 N Orlando Ave',
  'Ocoee - 1542 E Silver Star Rd',
  'Orlando - 12720 S Orange Blossom Trl',
  'Melbourne - 807 S Babcock St',
  'Merritt Island - 325 E Merritt Island Causeway',
  'Brandon - 105 Margaret St',
  'Tampa - 7525 W Waters Ave',
  'Tampa - 13745 N Dale Mabry',
  'Tampa - 2124 S Dale Mabry Hwy',
  'St Petersburg - 10101 Dr. Martin Luther King JR St N',
  'St Petersburg - 945 49th St N',
  'Clearwater - 2080 Gulf to Bay Blvd',
  'Largo - 8878 Ulmerton Rd',
  'Lakeland - 5143 US Hwy 98 N',
  'Winter Haven - 1540 3rd St SW',
  'Ocala - 2620 SW 17th Rd',
  'Weeki Wachee - 6276 Commercial Way',
  'Palm Harbor - 30657 US Hwy 19 N',
  'Leesburg - 1107 W N Blvd',
  'New Port Richey - 8729 Little Rd',
  'Wesley Chapel - 27607 State Rd 56',
  'Orlando - 11907 East Colonial',
  'Jacksonville - 14054 Beach Blvd',
  'Cape Coral - 130 Del Prado South',
  'Clermont - 1500 Oakley Seaver Drive',
  'Conyers - 1543 Hwy 138 SE',
  'Alpharetta - 11105 State Bridge Rd',
  'Marietta - 2727 Canton Rd',
  'Marietta - 1275 Powers Ferry Rd',
  'Roswell - 900 Mansell Road',
  'Snellville - 2314 Henry Clower Blvd',
  'Duluth - 3360 Satellite Blvd',
  'Douglasville - 7421 Douglas Blvd',
  'Rome - 610 Shorter Ave',
  'Woodstock - 9425 Hwy 92',
  'Fayetteville - 135 Banks Station',
  'Newnan - 218 Bullsboro Dr',
  'Atlanta - 3275 Peachtree Road, N.E.',
  'Gainesville - 250 John W Morrow Jr Pkwy',
  'Athens - 1063 Baxter St',
  'Dalton - 100 W Walnut Ave',
  'Augusta - 266 Bobby Jones Expy',
  'Martinez - 3830 Washington Rd',
  'Savannah - 8608 Abercorn St',
  'Valdosta - 1849 Norman Drive',
  'Tifton - 137 S Virginia Ave',
  'Columbus - 5300 Sidney Simons Blvd',
  'Macon - 6255 Zebulon Rd',
  'Cumming - 194 Tri County Plaza II',
  'Acworth - 3485 Acworth Due West Road',
  'Hoover - 3321 Lorna Rd',
  'Hoover - 1927 Hoover Ct',
  'Birmingham - 1808 Gadsden Hwy',
  'Tuscaloosa - 1663 N McFarland Blvd',
  'Dothan - 2767 Montgomery Hwy',
  'Jackson - 5010 Parkway Dr',
  'Gulfport - 9471 Three Rivers Rd',
  'Metairie - 3216 W Esplanade Ave N',
  'New Orleans - 4200 Canal Street',
  'Covington - 1170 Hwy 190',
  'Lafayette - 1889 W Pinhook Rd',
  'Lake Charles - 234 W Prien Rd',
  'Baton Rouge - 11445 Coursey Blvd',
  'Monroe - 1110 Oliver Rd',
  'Alexandria - 1707 Metro Dr',
  'Lexington - 205 Columbia Ave',
  'Columbia - 285 Columbiana Drive',
  'Spartanburg - 7750 Warren H Abernathy',
  'N Charleston - 8780 Rivers Ave',
  'Charleston - 1660 Sam Rittenberg Blvd',
  'Florence - 1237 Celebration Blvd',
  'Myrtle Beach - 1271 38th Ave N',
  'Greenville - 415 N Pleasantburg Dr',
  'Greenville - 1607 Laurens Rd',
  'Anderson - 3122 N Main St',
  'Greenwood - 211 Birchtree Dr',
  'Rock Hill - 1025 Dorothy St',
  'Columbia - 111 Sparkleberry Crossing Road',
  'Winston Salem - 1527 Hanes Mall Blvd',
  'High Point - 1225 Eastchester Dr',
  'Cary - 120 Maynard Crossing Court',
  'Raleigh - 6010 Falls of Neuse Rd',
  'Durham - 3519 Witherspoon Blvd',
  'Greenville - 425 Greenville Blvd SE',
  'Gastonia - 230 E Garrison Blvd',
  'Mooresville - 227 E Plaza Dr',
  'Charlotte - 3211 Eastway Dr',
  'Charlotte - 8314 Pineville - Mathew Rd',
  'Matthews - 1830 Windsor Square Drive',
  'Charlotte - 9401 Statesville Rd',
  'Fayetteville - 3319 Raeford Rd',
  'Wilmington - 4512 Oleander Dr',
  'Wilmington - 341 S College Rd',
  'Hickory - 2535 Hwy 70 SE',
  'Hickory - 1317 10th Ave Ln SE',
  'Asheville - 801 Fairview Rd',
  'Burlington - 2753 S Church St',
  'Greensboro - 2717 Battleground Ave',
  'Franklin - 1945 Mallory Lane',
  'Goodlettsville - 770 Rivergate Pkwy',
  'Murfreesboro - 2441 Old Fort Pkwy',
  'Nashville - 850 Hillwood Blvd',
  'Chattanooga - 6231 Perimeter Dr',
  'Johnson City - 600 N State Of Franklin',
  'Knoxville - 6701 Maynardville Pike',
  'Knoxville - 8023 Kingston Pike',
  'Knoxville - 1645 Downtown W Blvd',
  'Jackson - 480 Wiley Parker Rd',
  'Hendersonville - 287 New Shackle Island Rd',
  'Nashville - 1516 8th Ave S',
  'Murfreesboro - 1103 NW Broad Street',
  'Lebanon - 529 W Baddour Parkway',
  'Plainfield - 154 N Perry Rd',
  'Indianapolis - 8747 S US Hwy 31',
  'Indianapolis - 8255 Craig St',
  'Merrillville - 7980 Broadway',
  'Mishawaka - 212 W Edison Rd',
  'Fort Wayne - 821 W Coliseum',
  'Muncie - 412 E McGalliard Rd',
  'Evansville - 857-859 North Green River Road',
  'Lafayette - 220 Meijer Dr',
  'Elkhart - 4536 Elkhart Rd',
  'Fort Wayne - 4916 Illinois Rd',
  'Anderson - 1844 Applewood Centre Dr',
  'Highland - 2645 Main St',
  'Evansville - 4412 W. Lloyd Expressway Parkway',
  'Carmel - 4771 East 126Th Street',
  'Marysville - 2303 Gratiot',
  'Madison Heights - 26103 John R Rd',
  'St Clair Shores - 24303 Harper Ave',
  'Troy - 6014 Rochester Rd',
  'Ann Arbor - 870 W Eisenhower',
  'Livonia - 19221 Newburgh Rd',
  'Monroe - 1309 N Telegraph',
  'Taylor - 23491 Eureka',
  'Shelby Township - 48870 Van Dyke Ave',
  'Waterford - 4234 Pontiac Lk Rd',
  'W Bloomfield - 6263 Orchard Lk Rd',
  'Flint - 2059 S Linden Rd',
  'Saginaw - 4480 Bay Rd',
  'Lansing - 2824 E Grand River Avenue',
  'Portage - 6428 S Westnedge',
  'St Joseph - 2080 Niles Rd',
  'Norton Shores - 5025 S Harvey St',
  'Grandville - 4693 Wilson Ave',
  'Grand Rapids - 6750 Kalamazoo Ave',
  'Grand Rapids - 2855 29th St',
  'Grand Rapids - 4485 Plainfield Ave NE',
  'Traverse City - 1358 S Airport Rd W',
  'Jackson - 920 N West Ave',
  'Holland - 12719 Riley St',
  'Dearborn - 22199 Michigan Ave',
  'Lansing - 6209 W Saginaw Hwy',
  'Sterling Heights - 43695 Schoenherr Road',
  'Novi - 47750 Grand River Ave',
  'Kalamazoo - 1700 S Drake Rd',
  'Pickerington - 1215 Hill Rd N',
  'Columbus - 8485 Sancus Blvd',
  'Sandusky - 5500 Milan Rd',
  'Toledo - 5122 Heatherdowns Blvd',
  'Toledo - 5201 Monroe St',
  'Mentor - 8758 Mentor Ave',
  'Lyndhurst - 5122 Mayfield Rd',
  'Middleburg Heights - 15240 Bagley Rd',
  'Fairlawn - 25 Ghent Rd',
  'Youngstown - 7393 California Ave',
  'Canton - 3986 Fulton Dr NW',
  'Fairfield - 4625 Dixie Hwy',
  'Cincinnati - 11935-37 Montgomery Rd',
  'Cincinnati - 8776 Colerain Ave',
  'Centerville - 679 Lyons Rd',
  'Columbus - 1129 Kenny Centre',
  'Cincinnati - 6210 Glenway Ave',
  'Columbus - 6686 Sawmill Rd',
  'Niles - 815 Youngstown Warren Rd',
  'North Olmsted - 25139 Lorain Rd',
  'Uniontown - 3700 Massillon Road',
  'Cincinnati - 7466 Beechmont Avenue',
  'Dunbar - 7 Fletcher Sq',
  'Fairfax - 11268 James Swart Cir',
  'Alexandria - 372 S Pickett St',
  'Fredericksburg - 3940 Plank Rd',
  'Richmond - 10048 Robius Rd',
  'Richmond - 7801 W Broad St',
  'Chesapeake - 1040 Volvo Pkwy',
  'Virginia Beach - 2736 Virginia Beach Blvd',
  'Newport News - 12638 Jefferson Ave',
  'Roanoke - 1052 23rd St S W',
  'Staunton - 105 Lee Jackson Hwy',
  'Lynchburg - 3700 Candlers Mtn Rd',
  'Sterling - 38 Pidgeon Hill Drive',
  'Woodbridge - 2974 Prince William Parkway',
  'Rockville - 12225 Nebel St',
  'Glen Burnie - 36 E Ordnance Rd',
  'Nottingham - 8728 Belair Rd',
  'Frederick - 919 North East St',
  'Hagerstown - 18019 Garland Groh Blvd',
  'Salisbury - 8245 Dickerson Ln',
  'Annapolis - 302 Harry S Truman Pkwy',
  'Columbia - 8865 Stanford Blvd',
  'West Lawn - 1110 W Wyomissing Blvd',
  'Allentown - 1980 Catasauqua Road',
  'Cranberry Township - 20325 Route 19',
  'East Norriton - 91 East Germantown Pike',
  'Edwardsville - 5 Gateway Shopping Center',
  'Lancaster - 245 Centerville Road',
  'Monroeville - 2528 Monroeville Boulevard',
  'Fairless Hills - 110 Lincoln Highway',
  'Warrington - 251 Easton Rd.',
  'Bridgeville - 1597 Washington Pike',
  'Erie - 6714 Peach St',
  'York - 2817 Concord Road',
  'Harrisburg - 2609 Brindle Drive',
  'Edison - 1795 Route 27',
  'Park Ridge - 168 Kinderkamack Rd',
  'Oakhurst - 2005 Route 35',
  'Brick - 990 Cedar Bridge Ave',
  'Parsippany - 752 Route 46 W',
  'Saddle Brook - 487 Market St',
  'Ledgewood - 1070 US Hwy 46',
  'Hamilton Square - 950 Route 33',
  'West New York - 4914 JF Kennedy Blvd',
  'Belleville - 351 Franklin Ave',
  'Englishtown - 372 US Highway 9',
  'Totowa - 195-A Route 46 West',
  'Somerdale - 711 Evesham Avenue East',
  'Greenbrook - 215/217 Route 22 East',
  'East Brunswick - 300 Rt 18',
  'Union - 1350 Galloping Hill Road',
  'Howell - 4065 Route 9',
  'Hillsborough Township - 450 Amwell Rd',
  'Wall Township - 1825 NJ-35',
  'Sewell - 415 Egg Harbor Road',
  'Elsmere - 1008 Kirkwood Highway',
  'Newark - 1245 Churchmans Road',
  'Staten Island - 1650 Richmond Ave',
  'Bohemia - 4497 Sunrise Highway',
  'Floral Park - 249-64 Jericho Turnpike',
  'Huntington - 1842 East Jericho Turnpike',
  'Middletown - 303 Tower Drive',
  'Miller Place - 691 Route 25A',
  'Nanuet - 250 West Route 59 #3',
  'Wantagh - 3437 Merrick Road',
  'White Plains - 350 Tarrytown Road',
  'Brooklyn - 307-309 Atlantic Avenue',
  'Bronx - 1937 Turnbull Avenue',
  'Berlin - 1092 Berlin Turnpike',
  'Groton - 992 Poquonnock Rd',
  'Brookfield - 195 Federal Road',
  'Enfield - 54 Hazard Ave',
  'Warwick - 1160 Post Rd',
  'Cranston - 1375 Park Ave',
  'W Springfield - 1458 Riverdale St',
  'Auburn - 567 Southbridge St',
  'Milford - 120 Medway Rd',
  'Natick - 1400 Worcester Rd',
  'Woburn - 14 Cummings Park',
  'Danvers - 151 Endicott St',
  'Malden - 1 Highland Ave',
  'Hanover - 1286 Washington St',
  'Randolph - 53 Mazzeo Dr',
  'Hyannis - 379 Iyannough Rd',
  'S Attleboro - 185 Washington St',
  'N Dartmouth - 654 State Rd',
  'Raynham - 473 South Street west',
  'Swansea - 25 Market St',
  'Dedham - 608 Providence Highway',
  'Leominster - 17 Sack Blvd',
  'Williston - 71 Knight Ln',
  'Rutland - 170 S Main St',
  'Nashua - 295 Daniel Webster Hwy',
  'Salem - 450 S Broadway',
  'Manchester - 545 Hooksett Rd',
  'Keene - 423 West Street',
  'Lebanon - 443 Miracle Mile',
  'Newington - 40 Old Dover Rd',
  'Nashua - 379 Amherst Street',
  'Concord - 14 Loudon Road',
  'Portland - 127 Marginal Way',
  'Auburn - 120 Center St',
  'Brewer - 365 Wilson St',
  'Augusta - 126 Western Ave',
  'Arlington Heights - 624 E Golf Rd',
  'Crystal Lake - 1095 Pingree Rd',
  'McHenry - 4005 Kane Ave',
  'Mundelein - 1460 S Butterfield Rd',
  'Palatine - 1274 E Dundee Rd',
  'Waukegan - 23 S Green Bay Rd',
  'Bloomingdale - 119 E Lake St',
  'W Dundee - 762 8th St',
  'Geneva - 728 E State St',
  'Glendale Heights - 59 E N Ave',
  'St Charles - 3341 W Main St',
  'Sycamore - 1967 Dekalb Ave',
  'Villa Park - 100 E Roosevelt Rd',
  'Berwyn - 6347 W Cermak Rd',
  'Shorewood - 852 Sharp Dr',
  'Joliet - 2342 Essington Rd',
  'Mokena - 19330 S La Grange Rd',
  'Oak Lawn - 9515 S Cicero Ave',
  'Orland Park - 15136 S LaGrange Rd',
  'Aurora - 391 SR-59',
  'Aurora - 1261 N Lake St',
  'Naperville - 620 E Ogden Ave',
  'Chicago - 2550 W Addison St',
  'Harwood Heights - 5142 N Harlem Ave',
  'Sterling - 4312 E Lincolnway',
  'Rockford - 5643 E State St',
  'Rock Island - 4016 Blackhawk Rd',
  'Ottawa - 2707 N Columbus St',
  'Peru - 3815 Progress Blvd',
  'Peoria - 2524 W Farrelly Ave',
  'Normal - 115 Susan Dr',
  'Champaign - 1305 N Mattis Ave',
  'Springfield - 1650 Wabash Ave',
  'Machesney Park - 1003 W Lane Rd',
  'Morton Grove - 7931 Golf Rd',
  'Oswego - 330 5th Street',
  'Chicago - 2000 N. Clybourn Avenue',
  'Kenosha - 7519 60th Ave',
  'Wauwatosa - 3720 N 124th St',
  'Greenfield - 3779 S 108th Street',
  'Madison - 1645 Thierer Rd',
  'Madison - 7475 Mineral Pt Rd',
  'Green Bay - 129 S Military Ave',
  'Wausau - 605 S 24th Ave',
  'Onalaska - 304 Sand Lk Rd',
  'Appleton - 3427 W College Ave',
  'Janesville - 1809 Milton Ave',
  'Eau Claire - 2855 Mall Dr',
  'Menomonee - W176 N9344 Rivercrest Drive',
  'Maplewood - 1845 County Rd D',
  'Richfield - 6325 Penn Ave S',
  'Minneapolis - 4321 Winnetka Ave N',
  'St Cloud - 4180 Thielman Ln',
  'Burnsville - 14011 Grand Ave',
  'Rochester - 4229 Highway 52 North',
  'Blaine - 1351 113th Ave N',
  'Ankeny - 1701 SE Delaware Ave',
  'Des Moines - 6058 SE 14th St',
  'Urbandale - 3314 100th St',
  'Mason City - 1631 4th St SW',
  'Waterloo - 914 Laporte Rd',
  'Council Bluffs - 1131 Lew Ross Rd',
  'Dubuque - 2600 Dodge St',
  'Coralville - 1801 2nd St',
  'Cedar Rapids - 2347 Blairs Ferry Rd NE',
  'W Burlington - 109 W Agency Rd',
  'Davenport - 585 E 53rd St',
  'Davenport - 2124 E. Kimberly Rd',
  'Sioux City - 4501 Southern Hills Drive',
  'St Joseph - 139 N Belt Hwy',
  'Lawrence - 2329 S Iowa St',
  'Overland Park - 9202 Flint St',
  'Manhattan - 1130 Westport Dr',
  'Topeka - 120 NW Gordon St',
  'Wichita - 3806 W Douglas Ave',
  'Wichita - 650 N Carriage Pkwy',
  'Salina - 2140 Planet Ave',
  'Hays - 715 Main St',
  'Dodge City - 11138 Kliesen St',
  'Lenexa - 12625 W 92nd St',
  'Topeka - 2800 SW Wanamaker RD',
  'Omaha - 8562 Park Dr',
  'Omaha - 2924 N 108th St',
  'Lincoln - 621 N 48th St',
  'Grand Island - 814 Diers Ave',
  'N Platte - 600 E Francis',
  'Scottsbluff - 3018 Ave I',
  'Omaha - 13743 F St',
  'Lincoln - 1501 Pine Lake Road',
  'Sioux Falls - 3504 S Minnesota Ave',
  'Aberdeen - 1409 6th Ave SE',
  'Rapid City - 3465 Sturgis Rd',
  'Sioux Falls - 2400 South Marion Road',
  'Fargo - 2119 13th Ave S',
  'Bismarck - 931 S 9th St',
  'Minot - 3220 S Broadway St',
  'Fargo - 4900 13th Ave S',
  'Casper - 4155 Legion Ln',
  'Arvada - 7735 Wadsworth Blvd',
  'Aurora - 2229 S Peoria',
  'Greenwood Village - 9682 Unit C E Arapahoe Rd',
  'Littleton - 1621 West Canal Circle',
  'Denver - 562 S Broadway',
  'Lakewood - 3200 S Wadsworth Blvd',
  'Denver - 5075 E Leetsdale Dr',
  'Longmont - 700 Ken Pratt Blvd',
  'Fort Collins - 4613 S Mason St D3-5',
  'Loveland - 1451 Denver Ave',
  'Greeley - 4731 W 10th St',
  'Colorado Springs - 3827 N Academy Blvd',
  'Colorado Springs - 1753 Briargate Blvd',
  'Pueblo - 1000 W 6th St',
  'Grand Junction - 2454 Hwy 6 & 50',
  'Grand Junction - 2493 US Hwy 6 & 50',
  'Boudler - 3200 Valmont Rd',
  'Ft. Morgan - 225 Main St',
  'Westminister - 12031 Tejon St',
  'Kent - 328 Washington Ave S',
  'Redmond - 2560 152nd Ave NE',
  'Seattle - 840 Northgate Way',
  'Tukwila - 6450 Southcenter Blvd',
  'Everett - 11120 Evergreen Way',
  'Bellingham - 141 Telegraph Rd',
  'Puyallup - 14201 Meridian E',
  'Silverdale - 3276 NW Plaza Rd',
  'Tacoma - 6050 Tacoma Mall Blvd',
  'Olympia - 3530 Pacific Ave SE',
  'Vancouver - 4804 Thurston Way',
  'Yakima - 2201 W Nob Hill Blvd',
  'Spokane - 122 East Montgomery',
  'Kennewick - 6515 W Clearwater',
  'Federal Way - 31875 Gateway Ctr Blvd S',
  'Seattle - 1220 Republican St',
  'Wenatchee - 1217 N Wenatchee Ave',
  'Spokane - 12120 E. Mission Avenue',
  'Lynnwood - 3105 Alderwood Mall Boulevard',
  'Clackamas - 16190 SE 82nd Dr',
  'Lake Oswego - 15450 SW Boones Ferry Rd',
  'Portland - 1006 SE Grand Ave',
  'Portland - 6001 NE Win Sivers Dr',
  'Portland - 7417 SW Beaverton Hillsdale Hwy',
  'Salem - 446 NE Lancaster Dr',
  'Eugene - 1020 Green Acres Rd',
  'Medford - 1112 Progress Dr',
  'Bend - 20205 Powers Rd',
  'Hillsboro - 1001 SE TV Highway',
  'Pocatello - 835 W Cedar',
  'Twin Falls - 566 Blue Lakes Blvd N',
  'Idaho Falls - 1530 Hollipark',
  'Lewiston - 2612 Nez Perce Dr',
  'Nampa - 322 Caldwell Blvd',
  'Boise - 5385 Kendall St',
  `Coeur d'Alene - 1600 N Government Way`,
  'Meridian - 3055 East Fairview Avenue',
  'Rexburg - 485 North 2nd',
  'Bountiful - 544 W 750 S',
  'Orem - 246 E University Parkway',
  'Sandy - 8924 South State Street',
  'Salt Lake City - 790 E 2100 S',
  'Salt Lake City - 1844 E 7000 S',
  'N Logan - 59 E 1600 N',
  'Riverdale - 4149 S Riverdale Rd',
  'St George - 157 E Riverside Dr',
  'American Fork - 945 W 500 N, Bldg. B',
  'Layton - 1992 West Antelope Drive',
  'Tooele - 162 North Main Street',
  'Taylorsville - 2530 West 4700 S',
  'Riverton - 2588 W 12600 S',
  'West Jordan - 2573 West 7800 South',
  'Spanish Fork - 882 North 600 East',
  'Ogden - 185 W. 12th Street',
  'Cedar City - 2052 W Cross Hollow Road',
  'South Jordan - 11685 S Trail Crossing Dr',
  'Phoenix - 742 E Glendale Ave',
  'Mesa - 832 W Baseline Rd',
  'Chandler - 6125 W Chandler Blvd',
  'Scottsdale - 10303 N Scottsdale Rd',
  'Glendale - 4330 W Union Hills Dr',
  'Tucson - 4343 N Oracle Rd',
  'Tucson - 6061 E Broadway Blvd',
  'Prescott - 1260 Gail Gardner Way',
  'Phoenix - 9925 W Camelback Rd',
  'Mesa - 2080 S. Power Road',
  'Phoenix - 245 E Bell Rd',
  'Scottsdale - 3320 N Hayden Rd',
  'Surprise - 15609 W Bell Rd',
  'Gilbert - 2721 South Santan Village Parkway',
  'Goodyear - 1981 North Pebblecreek Parkway',
  'Glendale - 5830 West Thunderbird Road',
  'Queen Creek - 21805 S. Ellsworth Rd',
  'Chandler - 4960 S Alma School Rd',
  'Henderson - 100 N Pecos',
  'Las Vegas - 7450 W Cheyenne Ave',
  'Las Vegas - 3655 S Durango Rd',
  'Las Vegas - 5191 W Charleston Blvd',
  'Reno - 949 W Moana Ln',
  'Carson City - 4332 S Carson Street',
  'Las Vegas - 7505 S. Rainbow Boulevard',
  'Sparks - 4731 Galleria Pkwy',
  'Henderson - 1205 West Warm Springs Rd',
  'Visalia - 3737 S Mooney Blvd',
  'Bakersfield - 3788 Ming Ave',
  'Clovis - 195 W Shaw Ave',
  'Fresno - 6751 N Blackstone Ave',
  'Salinas - 959 N Main St',
  'Monterey - 2260 N. Fremont St.',
  'San Francisco - 5237 Geary Blvd',
  'San Mateo - 2501 El Camino Real',
  'American Canyon - 3417 Broadway',
  'Concord - 2962 Treat Blvd',
  'Fremont - 39199-A Farwell Dr',
  'Dublin - 7740 Dublin Blvd',
  'San Rafael - 781 Andersen Dr',
  'Bakersfield - 2816 Calloway Dr',
  'Los Gatos - 15545 Los Gatos Blvd',
  'Capitola - 2001 40th Avenue',
  'San Jose - 124 Blossom Hill Rd',
  'San Jose - 860 N Winchester Blvd',
  'Stockton - 5759 Pacific Avenue',
  'Modesto - 3500 Sisk Rd',
  'Santa Rosa - 400 W Third St',
  'Citrus Heights - 8083 Greenback Ln',
  'Elk Grove - 8694 Elk Grove Blvd',
  'Folsom - 409 Glenn Dr',
  'Roseville - 212 Harding Blvd',
  'Vacaville - 211-B Peabody Rd',
  'Sacramento - 1537 Howe Ave',
  'Chico - 2201 Pillsbury Rd',
  'Yuba City - 1258 Stabler Ln',
  'Redding - 174 Hartnell Ave',
  'Brentwood - 2415 Empire Ave',
  'Danville - 760 Camino Ramon',
  'Auburn - 1760 Grass Valley Hwy',
  'Morgan Hill - 16060 Monterey Rd',
  'Tracy - 3214 W Grant Line Rd',
  'Turlock - 2905 N Tegner Rd',
  'Redwood City - 2517 El Camino Real',
  'Petaluma - 901 E Washington St',
  'Alameda - 833 Marina Village Parkway',
  'El Cerrito - 250 El Cerrito PLZ',
  'San Jose - 1956 Tully Road',
  'Sacramento - 2087 Arena Blvd',
  'Los Angeles - 12211 Santa Monica Blvd',
  'W Hollywood - 8539 W Sunset Blvd',
  'Downey - 8605 Firestone Blvd',
  'Torrance - 21223 Hawthorne Blvd',
  'Whittier - 11741 Whittier Blvd',
  'Signal Hill - 2530 Cherry Ave',
  'Arcadia - 411 E Huntington Dr',
  'Pasadena - 245 N Lake Ave',
  'Santa Clarita - 23428 Lyons Ave',
  'Northridge - 17036 Devonshire St',
  'Thousand Oaks - 434 N Moorpark Rd',
  'Woodland Hills - 19718 Ventura Blvd',
  'Sherman Oaks - 14425 Ventura Blvd',
  'Burbank - 1121 N San Fernando Rd',
  'Rancho Cucamonga - 7180 Archibald Ave',
  'Fullerton - 1545 S. Harbor Blvd',
  'Upland - 1426 W 7th St',
  'West Covina - 500 N Azusa Ave',
  'El Cajon - 850A Jackman St',
  'Escondido - 1213 E Valley Pkwy',
  'Oceanside - 2216 El Camino Real',
  'San Diego - 940 University Ave',
  'San Diego - 7420 Clairemont Mesa Blvd',
  'San Diego - 6348 College Grove Way',
  'Rancho Mirage - 42358 Bob Hope Dr',
  'Redlands - 827 Tri City Center Dr',
  'Victorville - 12401 Hesperia Road',
  'Riverside - 5222 Arlington Ave',
  'Temecula - 41125 Winchester Rd',
  'Costa Mesa - 435 E 17th St',
  'Dana Point - 24835 Del Prado',
  'Lake Forest - 23532 El Toro Rd',
  'Huntington Beach - 16773 Beach Blvd',
  'Santa Ana - 3775 S Plaza Dr',
  'Brea - 604 S Brea Blvd',
  'Orange - 1040 E Katella Ave',
  'Corona - 2191 Sampson Ave',
  'Ventura - 2887 Johnson Dr',
  'Simi Valley - 660 E Los Angeles Ave',
  'San Luis Obispo - 3121 S Higuera St',
  'Santa Maria - 1405 S Broadway',
  'Lancaster - 2043 W Ave K',
  'Lakewood - 4202 Woodruff Ave',
  'San Diego - 10175 Rancho Carmel Dr',
  'Alhambra - 721 E Main St',
  'Glendale - 306 N Glendale Ave',
  'National City - 1747 Sweetwater Rd',
  'Menifee - 29800 Bradley Rd',
  'Fontana - 17122 Slover Ave',
  'Santa Barbara - 3953 State Street',
  'Culver City - 9901 Washington Blvd',
  'Encinitas - 207 South El Camino Real',
  'Chino - 3860 Grand Ave',
  'Glendora - 334 & 338 East Route 66',
  'Tustin - 13662 Newport Ave',
  'Riverside - 6211 Valley Springs Parkway',
  'Cypress - 10953 Meridian Drive',
  'Hawthorne - 5207 West Rosecrans Avenue',
  'San Diego - 1975 Garnet Ave',
  'Murrieta - 25285 Madison Ave',
  'Los Angeles - 225 N. Vermont Avenue',
  'San Marcos - 184 S Rancho Sante Fe Rd',
  'Mission Viejo - 28892 Marguerite Parkway',
  'Rocklin - 6632 Lonetree Blvd.',
  'Camarillo - 1775 E Daily',
  'Skyline Ranch - 19335 Plum Canyon Rd',
  'La Quinta - 46480 Washington St.',
  'El Paso - 1360 Lee Trevino',
  'West El Paso - 5860 N. Mesa Street'
];

export const partnersUrl = 'https://go.beautista.com/partners/';
