import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Carousel,
  Typography,
  Tooltip,
  Modal,
  message,
  Anchor,
  Dropdown,
  Menu,
} from "antd";
import _ from "lodash";
import { getToken } from "../../utils/authStorage";
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import IconPhotoEmpty from "../../assets/images/ic_photo_empty.svg";
import IconCheck from "../../assets/images/ic_check_no_border.svg";
import IconBookmark from "../../assets/images/ic_bookmark.svg";
import IconMessage from "../../assets/images/ic_message_black.svg";
import IconVideo from "../../assets/images/ic_video.svg";
import IconPlay from "../../assets/images/ic_play.svg";
import IconLocation from "../../assets/images/ic_location.svg";
import IconFeatureEmpty from "../../assets/images/ic_feature_photo_empty.svg";
import IconImageEmpty from "../../assets/images/ic_image_empty.svg";
import IconAward from "../../assets/images/ic_award.svg";
import PlaceHolderMap from "../../assets/images/ph_map.jpg";
import IconFacebook from "../../assets/images/ic_facebook.svg";
import IconTiktok from "../../assets/images/ic_tiktok.svg";
import IconYoutube from "../../assets/images/ic_youtube.svg";
import IconIns from "../../assets/images/ic_instagram.svg";
import IconPlayWhite from "../../assets/images/ic_play_white.svg";
import IconMenu from "../../assets/images/ic_menu.svg";
import IconClose from "../../assets/images/ic_close_black.svg";
import IconSuccess from "../../assets/images/ic_check.svg";
import IconBack from "../../assets/images/ic_back.svg";
import IconCanvasName from "../../assets/images/ic_home_location.svg";
import IconInfo from "../../assets/images/ic_help_grey.svg";
import IconAwardCanvas from "../../assets/images/ic_award_canvas.svg";
import VideoModal from "../../components/component_video_modal";
import { addBookmark } from "../../api/Bookmark";
import { sendMsgEmail } from "../../api/Common";
import { getInstaUserInfo, getMedias } from "../../api/Insta";
import "./canvas_preview.less";
import {
  CanvasType,
  ClassType,
  router_homepage_canvas,
  router_homepage_index,
  router_homepage_opportunity,
  router_opportunity_detail,
  router_sign_in,
} from "../../utils/enum";
import { Link } from "react-router-dom";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import moment from "moment";
import { global_router } from "../../routers";
import { getOppsOfcanvas } from "../../api/Opportunity";
import { doAction, doFunction, getModuleProp } from "module-reaction";
import { MODULE_HOMEPAGE_OPPORTUNITY } from "../../models/model_homepage_opportunity";
import { customLocationCountMap, getOptimizedPath } from "../../utils/utils";
import { getStaticAssetPath } from "../../utils/utils";
import { useLocation, useHistory } from 'react-router-dom';
import { UpdatePageInfoAction } from "../../models/model_business_canvas";

const { Link: AnLink } = Anchor;

const { Paragraph } = Typography;
declare var google: any;
const retailPH = [
  { name: "First Brand" },
  { name: "Second Brand" },
  { name: "Third Brand" },
  { name: "Fourth Brand" },
  { name: "Fifth Brand" },
  { name: "Sixth Brand" },
  { name: "Seventh Brand" },
  { name: "More brands" },
];

const softwarePH = [
  { name: "First Software" },
  { name: "Second Software" },
  { name: "Third Software" },
  { name: "Fourth Software" },
  { name: "Fifth Software" },
  { name: "Sixth Software" },
  { name: "Seventh Software" },
  { name: "More Software" },
];

const backbarPH = [
  { name: "First Brand" },
  { name: "Second Brand" },
  { name: "Third Brand" },
  { name: "Fourth Brand" },
  { name: "Fifth Brand" },
  { name: "Sixth Brand" },
  { name: "Seventh Brand" },
  { name: "More brands" },
];

const specialtiesPH = [
  { name: "First Specialty" },
  { name: "Second Specialty" },
  { name: "Third Specialty" },
  { name: "Fourth Specialty" },
];
const benefitsPH = [
  { name: "First Benefit" },
  { name: "Second Benefit" },
  { name: "Third Benefit" },
  { name: "Fourth Benefit" },
  { name: "Fifth Benefit" },
  { name: "Sixth Benefit" },
  { name: "Seventh Benefit" },
  { name: "More Benefit" },
];

const charitiesPH = [
  { name: "First Charity" },
  { name: "Second Charity" },
  { name: "Third Charity" },
  { name: "Fourth Charity" },
  { name: "Fifth Charity" },
  { name: "Sixth Charity" },
  { name: "Seventh Charity" },
  { name: "More Charity" },
];

const locationPh = [
  { location: "Address #1" },
  { location: "Address #2" },
  { location: "Address #3" },
  { location: "Address #4" },
  { location: "Address #5" },
];

const programPh = [
  { name: "Program #1" },
  { name: "Program #2" },
  { name: "Program #3" },
  { name: "Program #4" },
  { name: "Program #5" },
  { name: "Program #6" },
  { name: "Program #7" },
  { name: "Program #8" },
];

interface Props {
  type?: string;
  mode: PreviewMode;
  pageData: any;
  isMobile?: boolean;
  isDetail?: boolean;
  curPosition?: BusPositionTag;
  onMessage?: Function;
  onGoBack?: Function;
}

export enum PreviewMode {
  CREATE = 0,
  EDIT = 1,
  VIEW = 2,
}

export enum BusPositionTag {
  NONE = 0,
  HEADLINE = 1,
  DETAIL = 2,
  WHY = 3,
  ADDITIONAL = 4,
  AWARD = 5,
  INS = 6,
  SOCIAL = 7,
  TITLE = 8,
}
// const staticMapBaseUrl = "https://maps.googleapis.com/maps/api/staticmap?";

const CanvasPreview: React.FC<Props> = (props) => {
  const {
    mode,
    pageData,
    type = CanvasType.BUSINESS,
    isMobile = false,
    isDetail = false,
    onMessage,
    curPosition,
    onGoBack,
  } = props;

  const [detail, setDetail] = useState({} as any);
  const [curVideo, setCurVideo] = useState({} as any);
  const [curLocation, setCurLocation] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [showMapPH, setShowMapPH] = useState(true);
  const [curCoor, setCurCoor] = useState([0, 0]);
  const [curPlayUrl, setCurPlayUrl] = useState("");
  const [proLocations, setProLocations] = useState([] as any[]);
  const [curProLocation, setCurProLocation] = useState("");
  const [locationPrograms, setLocationPrograms] = useState([] as any[]);
  const [insName, setInsName] = useState("");
  const [insPhotos, setInsPhotos] = useState([] as any[]);
  const [isCurAccredit, setCurAccredit] = useState(false);
  const [isCurDayNNight, setCurDayNNight] = useState(false);
  const [isNoPrograms, setNoPrograms] = useState(false);
  const [isBookmark, setBookmark] = useState(false);
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [msgContent, setMsgContent] = useState("");
  const [txtMore, setTxtMore] = useState(false);
  const [myJobs, setMyJobs] = useState([])

  const isFirstUpdate = useRef(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    if(curPlayUrl !== '') {
      if (searchParams.get('autoplay') !== 'true') {
        searchParams.set('autoplay', 'true');
        window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
      }
    } else {
      searchParams.delete('autoplay');
      window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
    }
  }, [curPlayUrl])

  useEffect(() => {
    if (curPosition) {
      switch (curPosition) {
        case BusPositionTag.HEADLINE:
          elementIntoView("headline", 100);
          break;
        case BusPositionTag.WHY:
          elementIntoView("our-team", 100);
          break;
        case BusPositionTag.DETAIL:
          elementIntoView("detail", 100);
          break;
        case BusPositionTag.ADDITIONAL:
          elementIntoView("extra-info", 100);
          break;
        case BusPositionTag.AWARD:
          elementIntoView("awards", 100);
          break;
        case BusPositionTag.INS:
          elementIntoView("ins", 100);
          break;
        case BusPositionTag.SOCIAL:
          elementIntoView("social-media", 100);
          break;
        case BusPositionTag.TITLE:
          elementIntoView("about-us", 100);
          break;
      }
    }
  }, [curPosition]);

  useEffect(() => {
    if (_.isEmpty(pageData.locations) || !google || customLocationCountMap.has(pageData.id)) return;
    let index = curLocation;
    if (index >= pageData.locations.length) {
      index = pageData.locations.length - 1;
      setCurLocation(index);
    }
    const location = pageData.locations[index];
    if (!_.isEmpty(location.coordinates) && location.coordinates.length === 2) {
      if (
        curCoor[0] === location.coordinates[0] &&
        curCoor[1] === location.coordinates[1]
      ) {
        return;
      } else {
        setCurCoor([location.coordinates[0], location.coordinates[1]]);
      }
      const locate = {
        lng: location.coordinates[0],
        lat: location.coordinates[1],
      };
      setShowMapPH(false);
      const map = new google.maps.Map(document.getElementById("map"), {
        center: locate,
        zoom: 15,
      });
      new google.maps.Marker({
        map: map,
        position: locate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curLocation, pageData.locations]);

  const elementIntoView = (id: string, delay: number, block?: any) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element)
        element.scrollIntoView({
          behavior: "smooth",
          block: block || "center",
        });
    }, delay || 1000);
  };

  useEffect(() => {
    if (!_.isEmpty(pageData.locations)) {
      let employNum = 0;
      let chairs = 0;
      let sqft = 0;
      let locationStr = "";
      let dayStu = 0;
      let nightStu = 0;

      _.forEach(pageData.locations as any[], (item, index) => {
        if (item.employeeNumber) {
          employNum += Number(item.employeeNumber);
        }
        if (item.chairs) {
          chairs += Number(item.chairs);
        }
        if (item.area) {
          if (item.areaUnit && item.areaUnit === "ft") {
            sqft += Number(item.area);
          } else if (item.areaUnit && item.areaUnit === "m") {
            sqft += Math.floor(Number(item.area) * 10.76);
          }
        }
        if (index < 3) {
          locationStr =
            locationStr + `${(item.location && `${item.location}, `) || ""}`;
        }
        if (item.dayStudents) {
          dayStu += Number(item.dayStudents);
        }
        if (item.nightStudents) {
          nightStu += Number(item.nightStudents);
        }
      });
      if (pageData.locations.length > 3) {
        locationStr =
          locationStr + `and ${pageData.locations.length - 3} more locations`;
      }
      if (locationStr) {
        locationStr = " in " + locationStr;
      }
      if (locationStr.endsWith(", ")) {
        locationStr = locationStr.substring(0, locationStr.length - 2);
      }
      setDetail({
        employNum: employNum,
        chairs: chairs,
        sqft: sqft,
        locationStr: locationStr,
        locations: pageData.locations,
        dayStu: dayStu,
        nightStu: nightStu,
      });
    }
    if (_.isEmpty(pageData.videos)) {
      setCurVideo({});
    }
    setBookmark(pageData.bookmarkTag || false);
  }, [pageData]);

  useEffect(() => {
    console.log('pageData', pageData)
    const fetchInstagramHandle = async () => {
      try {
        const response = await getInstaUserInfo(pageData.id)
        if (response.data?.instagramHandle) {
          setInsName(response.data.instagramHandle);
          doAction(UpdatePageInfoAction, {
            instagramHandle: response.data.instagramHandle,
          });
        } else {
          setInsName('');
          setInsPhotos([]);
        }
      } catch (error) {
        console.error('Error fetching Instagram info:', error);
      }
    }
    fetchInstagramHandle()
  }, [pageData.id, pageData.instagramHandle, pageData.instagramPhotos])

  useEffect(() => {
    if (pageData.id && insName) {
      getInsList(pageData.id);
    } else {
      setInsPhotos([]);
    }
  }, [pageData.id, insName]);

  const getInsList = async (id: number) => {
    const res = await getMedias(id + "", 8);
    if (res && !_.isEmpty(res.medias)) {
      // const top4 = _.take(res.medias, 4);
      setInsPhotos(res.medias);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(pageData.locations)) {
      const proLoc = ["All Locations"];
      let programs = [] as any[];
      _.forEach(pageData.locations as any[], (item, index) => {
        proLoc.push(item.location);
        programs = programs.concat(item.supportedProgram || []);
      });
      setNoPrograms(_.isEmpty(programs));
      setProLocations(proLoc);
      if (
        !curProLocation ||
        !_.find(proLoc, (item) => {
          return curProLocation === item;
        })
      ) {
        setCurProLocation(proLoc[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData.locations]);

  useEffect(() => {
    let isAcc = false;
    let isBoth = false;
    let programs = [] as any[];
    if (curProLocation === "All Locations") {
      _.forEach(pageData.locations, (loc) => {
        programs = programs.concat(loc.supportedProgram || []);
        if (loc.isAccredited) {
          isAcc = true;
        }
        if (loc.classType === ClassType.ALL) {
          isBoth = true;
        }
      });
    } else {
      const curLoc = _.find(pageData.locations, { location: curProLocation });
      if (curLoc) {
        programs = curLoc.supportedProgram || [];
        if (curLoc.isAccredited) {
          isAcc = true;
        }
        if (curLoc.classType === ClassType.ALL) {
          isBoth = true;
        }
      }
    }
    setLocationPrograms(_.uniqBy(programs, "id"));
    setCurAccredit(isAcc);
    setCurDayNNight(isBoth);
  }, [curProLocation, pageData.locations]);

  useEffect(() => {
    if (!_.isEmpty(pageData.videos)) {
      const stillHas = _.find(pageData.videos, { video: curVideo.video || curVideo.url });
      if (!stillHas) {
        setCurVideo(pageData.videos[0]);
      }
    }
  }, [pageData, curVideo]);

  useEffect(() => {
    if (!_.isEmpty(pageData.videos)) {
      const stillHas = _.find(pageData.videos, { video: curVideo.video || curVideo.url });
      if (!stillHas) {
        setCurVideo(pageData.videos[0]);
        const searchParams = new URLSearchParams(location.search);
        const autoplay = searchParams.get('autoplay');
        if (autoplay) {
          console.log('Autoplay is enabled.');
          console.log(pageData.videos[0])
          setCurPlayUrl(pageData.videos[0].video || pageData.videos[0].url || "");
        } else {
          console.log('Autoplay is disabled')
        }
      }
    }
  }, [location, pageData]);

  useEffect(() => {
    mode !== PreviewMode.CREATE && pageData.id && getOppsOfcanvas(pageData.id).then(res => {
      if (res?.list) {
        setMyJobs(res.list)
      }
    })
  }, [pageData.id])
  const getSocialIcon = (label: string) => {
    if (!label) return "";
    switch (label) {
      case "instagram":
        return IconIns;
      case "youtube":
        return IconYoutube;
      case "facebook":
        return IconFacebook;
      default:
        return IconTiktok;
    }
  };

  const addOrRemoveBookmark = async () => {
    if (!getToken()) {
      pageData.history.push({
        pathname: router_sign_in,
        search: `?from=${encodeURI(
          window.location.pathname + window.location.search.replace(/&/g, "￥")
        )}`,
      });
      return false;
    }
    const result = await addBookmark(
      "canvas",
      pageData.id,
      isBookmark ? "remove" : "add",
      ""
    );
    if (result && result.success) {
      setBookmark(!isBookmark);
    }
  };

  const getSubtitle = () => {
    if (CanvasType.BUSINESS === type) {
      const txtList = _.map(pageData.category, (item) => {
        return item.name === "Other" ? item.otherName : item.name;
      });
      return (
        <>
          {(txtList.length && (
            <span className="mr-16">
              <img src={IconAwardCanvas} alt="" />
              {(pageData.category && txtList.toString()) || "Business Category"}
            </span>
          )) ||
            ""}
          {(pageData?.locations?.length && !customLocationCountMap.has(pageData.id) && (
            <span className="flex">
              <LocationOnIcon />
              {pageData?.locations[0]?.location || "Location 1"}

              {pageData?.locations?.length > 1 && (
                <>
                  <i className="num-item">+{pageData.locations.length}</i>
                  <Anchor affix={false}>
                    <AnLink
                      href="#components-anchor-location"
                      title={<b>More Locations</b>}
                    />
                  </Anchor>
                </>
              )}
            </span>
          )) ||
            ""}
        </>
      );
    } else {
      if (!_.isEmpty(pageData.locations)) {
        return (
          <>
            <span className="flex">
              <LocationOnIcon />
              {pageData.locations[0].location}

              {pageData.locations.length > 1 && (
                <>
                  <i className="num-item">+{pageData.locations.length}</i>
                  <Anchor affix={false}>
                    <AnLink
                      href="#components-anchor-location"
                      title={<b>More Locations</b>}
                    />
                  </Anchor>
                </>
              )}
            </span>
          </>
        );
      } else {
        // if (mode === PreviewMode.VIEW) {
        //   return "";
        // } else {
        return "";
        // }
      }
    }
  };

  const redirectToSocial = (social: any) => {
    let value = (social.value as string) || "";
    if (!value || value === "@") return;
    if (value[0] === "@") {
      value = value.substring(1, value.length);
    }
    switch (social.label) {
      case "instagram":
        window.open(`https://www.instagram.com/${value}`);
        break;
      case "tiktok":
        window.open(`https://www.tiktok.com/@${value}`);
        break;
      case "youtube":
      case "facebook":
        if (value.startsWith("http")) {
          window.open(value);
        } else {
          window.open(`https://${value}`);
        }
        break;
    }
  };

  const getAwardText = (data: any) => {
    if (typeof data === "number") {
      return "Issue Year - Issuer";
    }
    if (data.issueYear && data.issuer) {
      return `${data.issueYear} - ${data.issuer}`;
    } else {
      return data.issueYear ? data.issueYear : data.issuer ? data.issuer : "";
    }
  };

  const getDateInterval = (createdAt: any) => {
    const date = moment(createdAt);
    const now = moment();
    const duration = moment.duration(now.diff(date));
    return duration.days();
  };

  const navigateToDtl = (slug: string) => {
    global_router.history.push({
      pathname: router_opportunity_detail.replace(":slug", slug),
    });
    // this.initPageData();
  };

  const handleViewAll = () => {
    doAction(MODULE_HOMEPAGE_OPPORTUNITY, {
      use_location: false,
      filters: {
        ...getModuleProp(MODULE_HOMEPAGE_OPPORTUNITY, 'filters'),
        keyword: pageData.companyName
      }
    })
    doFunction(async () => {
      global_router.history.push(router_homepage_opportunity);
    })

  }
  const renderJobsCard = (item: any, index: number) => {
    const {
      slug,
      media,
      canvasName,
      jobTitle,
      jobType,
      updatedAt,
      location,
    } = item;
    return (
      <div
        className="similiar-card"
        key={index}
        onClick={() => navigateToDtl(slug)}
      >
        <div className="image">
          <img
            src={
              (media && media.thumbPhoto && getOptimizedPath(media.thumbPhoto.image, 640)) ||
              IconFeatureEmpty
            }
            alt=""
          />
        </div>
        <div className="card-info-wrap">
          <div className="info-status">
            <div className="left-sec">
              <div className="type">{jobType}</div>
              <div className="date-status">New</div>
            </div>
            <div className="right-sec">
              Updated {getDateInterval(updatedAt)} days ago
            </div>
          </div>
          <div className="job-title">{jobTitle}</div>
          <div className="location-info" hidden={!location.address}>
            <LocationOnRoundedIcon className="card-icon" />
            <div className="location"> {location.address}</div>
          </div>
          <div className="canvas-info" hidden={!canvasName}>
            <img src={IconCanvasName} alt="" className="card-icon" />
            {canvasName}
          </div>
        </div>
      </div>
    );
  };

  const RenderInsList = (list: any[], isCarousel = false) => {
    return list.map((item: any, index: number) => {
      const img = item.thumbnail_url || item.media_url;
      const imgItem = (
        <div
          className="ins-item"
          key={"item" + index}
          onClick={() => window.open(`https://www.instagram.com/${insName}`)}
        >
          <div className="img">
            <img src={img} alt="" />
          </div>
        </div>
      );
      return isCarousel ? (
        <div className="item-wrap" key={"wrap" + index}>
          {imgItem}
        </div>
      ) : (
        imgItem
      );
    });
  };

  const getIssueText = (data: any) => {
    if (typeof data === "number") {
      return "Issue Year - Issuer";
    }
    if (data.issueYear && data.issuer) {
      return `${data.issueYear} - ${data.issuer}`;
    } else {
      return data.issueYear ? data.issueYear : data.issuer ? data.issuer : "";
    }
  };

  const getInsDisplayName = () => {
    if (insName) {
      if (insName.startsWith("@")) {
        return insName;
      } else {
        return `@${insName}`;
      }
    } else {
      return "@account";
    }
  };

  /* Send message */

  const sendMessage = () => {
    if (mode !== PreviewMode.VIEW) {
      return;
    }
    if (!getToken()) {
      const path = window.location.pathname;
      pageData.history && pageData.history.push(`/sign-in?from=${path}`);
    }
    setVisibleMsgModal(true);
  };

  const sendMsg = () => {
    if (!pageData.owner) {
      return;
    }
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: "msg_to_member",
      users: [pageData.owner],
      extra: {
        canvas_id: pageData.id,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
  };

  const [optionModal, setOptionModal] = useState<any>(null)

  const showAllOptions = (title: string, options: any[]) => {
    options.length && setOptionModal({ title, options })
  }

  const renderLocations = () => {
    const customLocations = customLocationCountMap.get(pageData.id);

    if (!!customLocations) {
      return <div className="detail-location-item">
        <span className="canvas-title">
          {customLocations}
        </span>
        <span className="detail-item-location-text">Locations</span>
      </div>;
    }

    return <div
      className="detail-location-item"
      hidden={!pageData.locations?.length}
    >
      <Dropdown
        overlay={
          <Menu>
            {pageData.locations.map((l: any) => (
              <Menu.Item>{l.location}</Menu.Item>
            ))}
          </Menu>
        }
        trigger={["click", "hover"]}
      >
        <span className="canvas-title">
          {(pageData.locations && pageData.locations.length) || "00"}
          <img alt="" src={IconLocation} />
        </span>
      </Dropdown>
      <span className="detail-item-location-text">Locations</span>
    </div>
  };

  return (
    <div
      className={classnames("preview-root", {
        isView: mode === PreviewMode.VIEW && !isDetail,
        isDetail: isDetail,
      })}
    >
      <div className="preview-header" id="about-us">
        <div className="logo-left-sec">
          <span
            className="btn-back"
            onClick={() => onGoBack && onGoBack()}
            hidden={!isDetail}
          >
            <img alt="" src={IconBack} />
            Back
          </span>
          <span
            className="logo-sec"
            hidden={PreviewMode.VIEW === mode && !pageData.logo}
          >
            <img alt="" src={pageData.logo || IconPhotoEmpty} />
            {!pageData.logo &&
              `${type === CanvasType.BUSINESS ? "Company" : "School"} Logo`}
          </span>
        </div>
        <div className="space" />
        <div className="guide-sec">
          <span
            className="guide-text"
            onClick={() => elementIntoView("about-us", 100)}
          >
            ABOUT US
          </span>
          <span
            className="guide-text"
            onClick={() => elementIntoView("our-team", 100)}
            hidden={PreviewMode.VIEW === mode && !pageData.whyJoin}
          >
            OUR TEAM
          </span>
          {!_.isEmpty(pageData.awardList) && (
            <span
              className="guide-text"
              onClick={() => elementIntoView("awards", 100)}
            >
              AWARDS
            </span>
          )}
          <span
            className="guide-text"
            onClick={() => elementIntoView("extra-info", 100)}
            hidden={
              PreviewMode.VIEW === mode &&
              _.isEmpty(pageData.additional.retailBrands) &&
              _.isEmpty(pageData.additional.softwares) &&
              _.isEmpty(pageData.additional.backbarBrands) &&
              _.isEmpty(pageData.additional.specialties) &&
              (!pageData.additional.paymentMode ||
                !pageData.additional.paymentMode.name) &&
              _.isEmpty(pageData.additional.companyBenefits) &&
              _.isEmpty(pageData.additional.charities)
            }
          >
            ADDITIONAL INFO
          </span>
          <span
            className="guide-text"
            onClick={() => elementIntoView("social-media", 100)}
            hidden={
              PreviewMode.VIEW === mode &&
              _.isEmpty(
                _.filter(pageData.socialMedias, (item: any) => {
                  return !_.isEmpty(item.value);
                })
              )
            }
          >
            SOCIAL MEDIA
          </span>
          {/* <Button className="btn-primary-light contact-btn">
            <img alt="" src={IconEmail} />
            CONTACT US
          </Button> */}
        </div>
        <img
          alt=""
          src={showMenu ? IconClose : IconMenu}
          className="icon-menu"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        />
      </div>
      <div className="dropdown-menu" hidden={!showMenu}>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("about-us", 100);
            setShowMenu(false);
          }}
        >
          About Us
        </span>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("our-team", 100);
            setShowMenu(false);
          }}
          hidden={PreviewMode.VIEW === mode && !pageData.whyJoin}
        >
          Our Team
        </span>
        {!_.isEmpty(pageData.awardList) && (
          <span
            className="menu-label"
            onClick={() => {
              elementIntoView("awards", 100);
              setShowMenu(false);
            }}
          >
            Awards
          </span>
        )}
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("extra-info", 100);
            setShowMenu(false);
          }}
          hidden={
            PreviewMode.VIEW === mode &&
            _.isEmpty(pageData.additional.retailBrands) &&
            _.isEmpty(pageData.additional.softwares) &&
            _.isEmpty(pageData.additional.backbarBrands) &&
            _.isEmpty(pageData.additional.specialties) &&
            (!pageData.additional.paymentMode ||
              !pageData.additional.paymentMode.name) &&
            _.isEmpty(pageData.additional.companyBenefits) &&
            _.isEmpty(pageData.additional.charities)
          }
        >
          ADDITIONAL INFO
        </span>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("social-media", 100);
            setShowMenu(false);
          }}
          hidden={
            PreviewMode.VIEW === mode &&
            _.isEmpty(
              _.filter(pageData.socialMedias, (item: any) => {
                return !_.isEmpty(item.value);
              })
            )
          }
        >
          Social Media
        </span>
        {/* <Button className="btn-primary-light contact-btn">
          <img alt="" src={IconEmail} />
          CONTACT US
        </Button> */}
        <div className="divider-menu" />
        <div className="made-with">
          Made with
          <span>
            <Link to={router_homepage_index}>BEAUTISTA</Link>
          </span>
        </div>
      </div>
      <div className="title-sec">
        <h1 className="company-name">
          {pageData.companyName || "Company Name"}
        </h1>
        <span className="tag">
          {type === CanvasType.BUSINESS ? "Business" : "School"}
        </span>
      </div>
      <div className="subtitle">{getSubtitle()}</div>
      <div className="msg-sec">
        <Button
          className="msg-btn btn-primary-light"
          onClick={() => mode === PreviewMode.VIEW && onMessage && onMessage()}
        >
          <img alt="" src={IconMessage} />
          Message me
        </Button>
        <Button
          className="msg-btn btn-secondary"
          onClick={() => addOrRemoveBookmark()}
        >
          <img alt="" src={IconBookmark} />
          {isBookmark ? "Remove Bookmark" : "Bookmark"}
        </Button>
        <Button
          className="msg-btn btn-secondary"
          onClick={() => {
            if (mode !== PreviewMode.VIEW) {
              return;
            }
            const apiHost = process.env.REACT_APP_BASE_URL;
            window.open(
              `${apiHost?.endsWith("/") ? apiHost : apiHost + "/"
              }canvas/canvas-pdf/${pageData.id}`
            );
          }}
        >
          <img alt="" src={IconBookmark} />
          Save as PDF
        </Button>
      </div>
      <div
        className="featured-img-sec"
        hidden={mode === PreviewMode.VIEW && _.isEmpty(pageData.featuredImage?.image)}
      >
        {pageData.featuredImage?.image ? (
          <img alt="" className="featured-img" src={getOptimizedPath(pageData.featuredImage.image, 1920)} style={{
            objectFit: pageData.featuredImage.keepSize ? 'contain' : 'cover',
            ...(pageData.featuredImage.reposition ? {
              objectPosition: `${pageData.featuredImage.reposition.left} ${pageData.featuredImage.reposition.top}`,
              objectFit: 'none'
              // ,width: `${pageData.featuredImage.reposition.width}`,height: `${pageData.featuredImage.reposition.height}`
            } : {})
          }} />
        ) : (
          <div className="featured-empty">
            <img alt="" src={IconFeatureEmpty} />
            Featured Image
          </div>
        )}
      </div>
      <div
        className="media-sec"
        hidden={
          PreviewMode.VIEW === mode &&
          _.isEmpty(pageData.videos) &&
          !pageData.headline &&
          !pageData.desc
        }
        id="headline"
      >
        <div className="media-center-sec">
          {PreviewMode.VIEW === mode && _.isEmpty(pageData.videos) ? (
            <div className="video-sec" />
          ) : (
            <div className="video-sec">
              <div className="main-video">
                {curVideo.video || curVideo.url ? (
                  <div className="featured-video-cover">
                    {curVideo.image || pageData.featuredImage.image ? (
                      <img
                        alt=""
                        className="featured-video-img"
                        src={curVideo.image || pageData.featuredImage.image}
                      />
                    ) : (
                      <span className="video-empty-image">
                        {!_.isEmpty(pageData.companyName) &&
                          pageData.companyName[0]}
                      </span>
                    )}
                    <img
                      alt=""
                      className="play-icon"
                      src={IconPlayWhite}
                      onClick={() => {
                        setCurPlayUrl(curVideo.video || curVideo.url || "");
                      }}
                    />
                  </div>
                ) : (
                  <div className="empty-video">
                    <img alt="" src={IconVideo} />
                    Featured Video
                  </div>
                )}
              </div>
              <div className="selector">
                {!_.isEmpty(pageData.videos) ? (
                  <div className="selector-content">
                    {_.map(pageData.videos, (video, index) => {
                      return (
                        <div
                          className={classnames("selector-item", {
                            isSelected: (curVideo.video || curVideo.url) === (video.video || video.url)
                          })}
                          key={index}
                          onClick={() => setCurVideo(video)}
                        >
                          <div className="selector-inner">
                            {video.image || pageData.featuredImage.image ? (
                              <img
                                alt=""
                                src={video.image || pageData.featuredImage.image}
                                className="inner-img"
                              />
                            ) : (
                              <span className="video-empty-image">
                                {!_.isEmpty(pageData.companyName) &&
                                  pageData.companyName[0]}
                              </span>
                            )}
                            <img
                              alt=""
                              src={IconPlayWhite}
                              className="play-icon"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="selector-empty">
                    <div className="selector-empty-wrap">
                      <img alt="" src={IconVideo} />
                    </div>
                    <div className="selector-empty-wrap">
                      <img alt="" src={IconVideo} />
                    </div>
                    <div className="selector-empty-wrap selector-empty-last">
                      <img alt="" src={IconVideo} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="text-sec">
            <h1
              className="video-title"
              hidden={mode === PreviewMode.VIEW && !pageData.headline}
            >
              {pageData.headline || "Headline"}
            </h1>
            <div className="video-desc"
              hidden={mode === PreviewMode.VIEW && !pageData.desc}
              dangerouslySetInnerHTML={{ __html: pageData.desc }} />
            {/* <div
                  dangerouslySetInnerHTML={{ __html: pageData.desc }}
                />
            </div> */}
            {/* <pre
              className="video-desc"
              hidden={mode === PreviewMode.VIEW && !pageData.desc}
            >
              {pageData.desc?.length > 300 && !txtMore
                ? pageData.desc.substr(0, 300) + "..."
                : pageData.desc || "Please input description here"}
              {pageData.desc?.length > 300 &&
                (!txtMore ? (
                  <span className="text-more" onClick={() => setTxtMore(true)}>
                    {" "}
                    See More
                  </span>
                ) : (
                    <span className="text-more" onClick={() => setTxtMore(false)}>
                      {" "}
                    See Less
                    </span>
                  ))}
            </pre> */}
            <Button
              className="btn-primary-light btn-watch"
              onClick={() => {
                if (
                  !_.isEmpty(pageData.videos) &&
                  (pageData.videos[0].video || pageData.videos[0].url)
                ) {
                  setCurPlayUrl(
                    pageData.videos[0].url || pageData.videos[0].video || ""
                  );
                }
              }}
              hidden={PreviewMode.VIEW === mode && _.isEmpty(pageData.videos)}
            >
              <img alt="" src={IconPlay} />
              Watch our video
            </Button>
          </div>
        </div>
        <div className="divider" />
      </div>
      {
        type === CanvasType.BUSINESS && _.isEmpty(pageData.contactNumber) ? null :
          <div className="detail-sec" id="detail">
            <div className="detail-title">
              <span className="title-text">Details</span>
              {
                _.isEmpty(pageData.locations) ? null :
                  <span className="info-sec">
                    <img alt="" src={IconInfo} />
                    {type === CanvasType.BUSINESS
                      ? "The number of Employees, Chairs and Square Footage are the summary of all locations"
                      : "The number of Employees and Students are the summary of all locations"}
                  </span>
              }
            </div>

            <div className="detail-content">
              <div
                className="detail-item canvas-title"
                hidden={
                  (!detail.dayStu && mode === PreviewMode.VIEW) ||
                  type === CanvasType.BUSINESS
                }
              >
                {detail.dayStu || "00"}
                <span>Day Students</span>
              </div>
              <div
                className="detail-item canvas-title"
                hidden={
                  (!detail.nightStu && mode === PreviewMode.VIEW) ||
                  type === CanvasType.BUSINESS
                }
              >
                {detail.nightStu || "00"}
                <span>Night Students</span>
              </div>
              <div
                className="detail-item canvas-title"
                hidden={!detail.employNum && mode === PreviewMode.VIEW}
              >
                {detail.employNum || "00"}
                <span>Happy Employees</span>
              </div>
              <div
                className="detail-item canvas-title"
                hidden={
                  (!detail.chairs && mode === PreviewMode.VIEW) ||
                  type === CanvasType.SCHOOL
                }
              >
                {detail.chairs || "00"}
                <span>Chairs</span>
              </div>
              <div
                className="detail-item canvas-title"
                hidden={
                  (!detail.sqft && mode === PreviewMode.VIEW) ||
                  type === CanvasType.SCHOOL
                }
              >
                {detail.sqft || "0000"}
                <span>Square Footage</span>
              </div>

              {renderLocations()}

              <div
                className="detail-item canvas-title phone-sec"
                hidden={!pageData.contactNumber}
              >
                {pageData.contactNumber || "(000) 000-0000"}
                <span>Primary Contact Number</span>
              </div>
            </div>
            <div className="divider" />
          </div>
      }
      <div
        className="programs-sec"
        hidden={
          CanvasType.BUSINESS === type ||
          (PreviewMode.VIEW === mode && isNoPrograms)
        }
      >
        <div className="programs-title">
          <span className="title-text">Available Programs</span>
          <TextField
            select
            label="Location"
            variant="filled"
            value={curProLocation}
            onChange={(e) => {
              setCurProLocation(e.target.value);
            }}
            className="material-input location-select"
          >
            {_.map(proLocations, (item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div
          className="programs-right-sec"
          hidden={_.isEmpty(locationPrograms) && PreviewMode.VIEW === mode}
        >
          <div className="programs-content">
            {_.map(
              _.isEmpty(locationPrograms)
                ? programPh
                : _.take(locationPrograms, 8),
              (item, idx) => {
                return (
                  <div className="info-item-list-item" key={`list-item${idx}`}>
                    <span
                      className={classnames("number", {
                        small: idx > 6,
                      })}
                    >
                      {idx > 6 && !_.isEmpty(locationPrograms)
                        ? `+${locationPrograms.length - 7}`
                        : idx > 6 && _.isEmpty(locationPrograms)
                          ? "+20"
                          : idx + 1}
                    </span>
                    <span className="value">
                      {idx > 6 ? "More Programs" : item.name}
                    </span>
                  </div>
                );
              }
            )}
          </div>
          <div className="add-sec" hidden={!isCurDayNNight && !isCurAccredit}>
            <span className="add-item" hidden={!isCurAccredit}>
              <img alt="" src={IconCheck} />
              Our school is accredited
            </span>
            <div
              className="add-divider"
              hidden={!isCurDayNNight || !isCurAccredit || isMobile}
            />
            <span className="add-item" hidden={!isCurDayNNight}>
              <img alt="" src={IconCheck} />
              Offer both day & night classes
            </span>
          </div>
        </div>
        <div className="divider" />
      </div>
      <div
        className="why-sec"
        id="our-team"
      // hidden={PreviewMode.VIEW === mode && !pageData.whyJoin}
      >
        <div className="text-sec">
          <span
            className="canvas-title"
            hidden={!pageData.whyJoin && mode === PreviewMode.VIEW}
          >
            {type === CanvasType.BUSINESS
              ? "Why join our team"
              : "Why Choose Our School"}
          </span>
          <div className="why-desc"
            hidden={mode === PreviewMode.VIEW && !pageData.whyJoin}
            dangerouslySetInnerHTML={{ __html: pageData.whyJoin }} />
          {/* <pre
            className="why-desc"
            hidden={!pageData.whyJoin && mode === PreviewMode.VIEW}
          >
            {pageData.whyJoin || "Please input description here (250 letters)."}
          </pre> */}
          {/* <Button
            className="btn-primary-light btn-drop"
            hidden={isMobile}
            onClick={() => {
              sendMessage();
            }}
          >
            <img alt="" src={IconEmail} />
            Drop us a letter
          </Button> */}
        </div>

        <div
          className="image-sec"
          hidden={PreviewMode.VIEW === mode && !pageData.teamPhoto?.image}
        >
          {pageData.teamPhoto?.image ? (
            <img alt="" className="team-photo" src={pageData.teamPhoto.image} style={{
              // objectFit: pageData.teamPhoto.keepSize ? 'contain' : 'cover',
              objectFit: 'contain',
              ...(pageData.teamPhoto.reposition ? {
                objectPosition: `${pageData.teamPhoto.reposition.left} ${pageData.teamPhoto.reposition.top}`,
                objectFit: 'none'
                // ,width: `${pageData.teamPhoto.reposition.width}`,height: `${pageData.teamPhoto.reposition.height}`
              } : {})
            }} />
          ) : (
            <div className="empty-image">
              <img alt="" src={IconImageEmpty} />
              Team Image
            </div>
          )}
        </div>
        {/* <Button
          className="btn-primary-light btn-drop"
          hidden={!isMobile}
          onClick={() => {
            sendMessage();
          }}
        >
          <img alt="" src={IconEmail} />
          Drop us a letter
        </Button> */}
      </div>
      <div
        className="additional-sec"
        id="extra-info"
        hidden={
          PreviewMode.VIEW === mode &&
          _.isEmpty(pageData.additional.retailBrands) &&
          _.isEmpty(pageData.additional.softwares) &&
          _.isEmpty(pageData.additional.backbarBrands) &&
          _.isEmpty(pageData.additional.specialties) &&
          (!pageData.additional.paymentMode ||
            !pageData.additional.paymentMode.name) &&
          _.isEmpty(pageData.additional.companyBenefits) &&
          _.isEmpty(pageData.additional.charities)
        }
      >
        <span className="canvas-title">Additional Information</span>
        <Modal
          maskClosable
          closable
          centered
          visible={optionModal !== null}
          title={optionModal?.title || ''}
          className="all-options-modal"
          width={500}
          footer={null}
          onCancel={() => setOptionModal(null)}
        >
          <div className="all-options-con">
            {
              optionModal?.options.map((o: any) =>
                <div className="option-name">{o.name}</div>
              )
            }
          </div>
        </Modal>
        <div className="additional-info-list">
          <div
            className={classnames("additional-info-item")}
            hidden={
              PreviewMode.VIEW === mode &&
              _.isEmpty(pageData.additional.retailBrands)
            }
          >
            <span className="info-title">Retail Brands</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.additional.retailBrands)
                  ? retailPH
                  : (_.take(pageData.additional.retailBrands, 8) as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span
                        className={classnames("number", {
                          small: idx > 6,
                        })}
                      >
                        {idx > 6 && !_.isEmpty(pageData.additional.retailBrands)
                          ? `+${pageData.additional.retailBrands.length - 7}`
                          : idx > 6 &&
                            _.isEmpty(pageData.additional.retailBrands)
                            ? "+20"
                            : idx + 1}
                      </span>
                      <span
                        className={classnames("value", { more: idx > 6 })}
                        onClick={idx > 6 ? e => showAllOptions('Retail Brands', pageData.additional.retailBrands) : undefined}
                      >
                        {idx > 6 ? "More Brands" : item.name}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={
              PreviewMode.VIEW === mode &&
              _.isEmpty(pageData.additional.softwares)
            }
          >
            <span className="info-title">Software</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.additional.softwares)
                  ? softwarePH
                  : (_.take(pageData.additional.softwares, 8) as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span
                        className={classnames("number", {
                          small: idx > 6,
                        })}
                      >
                        {idx > 6 && !_.isEmpty(pageData.additional.softwares)
                          ? `+${pageData.additional.softwares.length - 7}`
                          : idx > 6 && _.isEmpty(pageData.additional.softwares)
                            ? "+20"
                            : idx + 1}
                      </span>
                      <span
                        className={classnames("value", { more: idx > 6 })}
                        onClick={idx > 6 ? e => showAllOptions('Software', pageData.additional.softwares) : undefined}
                      >
                        {idx > 6 ? "More Software" : item.name}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={
              _.isEmpty(pageData.additional.backbarBrands) &&
              mode === PreviewMode.VIEW
            }
          >
            <span className="info-title">Backbar brands</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.additional.backbarBrands)
                  ? backbarPH
                  : (_.take(pageData.additional.backbarBrands, 8) as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span
                        className={classnames("number", {
                          small: idx > 6,
                        })}
                      >
                        {idx > 6 &&
                          !_.isEmpty(pageData.additional.backbarBrands)
                          ? `+${pageData.additional.backbarBrands.length - 7}`
                          : idx > 6 &&
                            _.isEmpty(pageData.additional.backbarBrands)
                            ? "+20"
                            : idx + 1}
                      </span>
                      <span
                        className={classnames("value", { more: idx > 6 })}
                        onClick={idx > 6 ? e => showAllOptions('Backbar brands', pageData.additional.backbarBrands) : undefined}
                      >
                        {idx > 6 ? "More Brands" : item.name}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={
              _.isEmpty(pageData.additional.specialties) &&
              mode === PreviewMode.VIEW
            }
          >
            <span className="info-title">Specialties</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.additional.specialties)
                  ? specialtiesPH
                  : (_.take(pageData.additional.specialties, 8) as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span
                        className={classnames("number", {
                          small: idx > 6,
                        })}
                      >
                        {idx > 6 && !_.isEmpty(pageData.additional.specialties)
                          ? `+${pageData.additional.specialties.length - 7}`
                          : idx > 6 &&
                            _.isEmpty(pageData.additional.specialties)
                            ? "+20"
                            : idx + 1}
                      </span>
                      <span
                        className={classnames("value", { more: idx > 6 })}
                        onClick={idx > 6 ? e => showAllOptions('Specialties', pageData.additional.specialties) : undefined}
                      >
                        {idx > 6 ? "More Specialties" : item.name}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={
              (PreviewMode.VIEW === mode &&
                (!pageData.additional.paymentMode ||
                  !pageData.additional.paymentMode.name)) ||
              (pageData.additional.paymentMode &&
                (pageData.additional.paymentMode.name === "Don't show" ||
                  173 === pageData.additional.paymentMode.id))
            }
          >
            <span className="info-title">Payment Preference</span>
            <div className="info-item-list">
              <div className="info-item-list-item">
                <span
                  className={classnames("value", {
                    isLong: true,
                  })}
                >
                  {(pageData.additional.paymentMode &&
                    pageData.additional.paymentMode.name) ||
                    "Payment based on Individual Performance"}

                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="benefits-sec"
          hidden={
            _.isEmpty(pageData.additional.companyBenefits) &&
            mode !== PreviewMode.CREATE
          }
        >
          <div className="top-divider" />
          <div className="benefits-content">
            <span className="benefits-title">Company Benefits</span>
            <div className="benefits-list">
              {_.map(
                _.isEmpty(pageData.additional.companyBenefits)
                  ? benefitsPH
                  : (_.take(pageData.additional.companyBenefits, 8) as any[]),
                (item, index) => {
                  return (
                    <div
                      className="benefits-list-item"
                      key={`list-item${index}`}
                    >
                      <span className="number">
                        {index > 6 &&
                          !_.isEmpty(pageData.additional.companyBenefits)
                          ? `+${pageData.additional.companyBenefits.length - 7}`
                          : index > 6 &&
                            _.isEmpty(pageData.additional.companyBenefits)
                            ? "+20"
                            : index + 1}
                      </span>
                      <span
                        className={classnames("value", { more: index > 6 })}
                        onClick={index > 6 ? e => showAllOptions('Company Benefits', pageData.additional.companyBenefits) : undefined}
                      >
                        {index > 6 ? "More Benefits" : item.name}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div
          className="benefits-sec"
          hidden={
            (_.isEmpty(pageData.additional.charities) &&
              mode !== PreviewMode.CREATE) ||
            type !== CanvasType.SCHOOL
          }
        >
          <div className="top-divider" />
          <div className="benefits-content">
            <span className="benefits-title">Charities</span>
            <div className="benefits-list">
              {_.map(
                _.isEmpty(pageData.additional.charities)
                  ? charitiesPH
                  : (_.take(pageData.additional.charities, 8) as any[]),
                (item, index) => {
                  return (
                    <div
                      className="benefits-list-item"
                      key={`list-item${index}`}
                    >
                      <span className="number">
                        {index > 6 && !_.isEmpty(pageData.additional.charities)
                          ? `+${pageData.additional.charities.length - 7}`
                          : index > 6 &&
                            _.isEmpty(pageData.additional.charities)
                            ? "+20"
                            : index + 1}
                      </span>
                      <span
                        className={classnames("value", { more: index > 6 })}
                        onClick={index > 6 ? e => showAllOptions('Charities', pageData.additional.charities) : undefined}
                      >
                        {index > 6 ? "More Charities" : item.name}
                      </span>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="awards-sec"
        hidden={_.isEmpty(pageData.awardList) && mode !== PreviewMode.CREATE}
        id="awards"
      >
        <span className="canvas-title">Awards and Achievements</span>
        <div className="awards-list">
          {_.map(
            !_.isEmpty(pageData.awardList) ? pageData.awardList : [0, 1, 2, 3],
            (item, idx) => {
              return (
                <div className="awards-item-empty" key={idx}>
                  {item?.title?.includes('Redken Safe Space Educated') ? (
                    <a href="https://u.canvasme.com/course/safe-space/" target="blank">
                      <img alt="Redken Award" style={{ width: '100px' }} src={getStaticAssetPath('redken.png')} />
                      <Tooltip title={item.title || "Title"}>
                        <Paragraph
                          className="title"
                        >
                          {item.title || "Title"}
                        </Paragraph>
                      </Tooltip>
                      <Tooltip title={getAwardText(item)}>
                        <Paragraph
                          ellipsis={{ rows: 1, expandable: true }}
                          className="issue"
                        >
                          {getAwardText(item)}
                        </Paragraph>
                      </Tooltip>
                    </a>
                  ):(
                    <>
                      {
                        item?.title?.includes('NAMI Head Up Completion') && (
                          <img
                            alt="Head Up Completion"
                            style={{ width: '200px' }}
                            src={getStaticAssetPath('heads-up-badge.png')}
                          />
                        )
                      }

                      {
                        item?.title?.includes('Purpose Discovery') && (
                          <img
                            alt="Purpose Discovery/EQ 101"
                            style={{ width: '100px' }}
                            src={getStaticAssetPath('defining-your-purpose-badge.svg')}
                          />
                        )
                      }

                      {
                        item?.title === 'Beacon' && item?.issueYear === '2024' && (
                          <>
                            <img
                              alt="Beacon Badge"
                              style={{ width: '100px' }}
                              src={getStaticAssetPath('pba-beacon-badge.png')}
                            />
                          </>
                        )
                      }

                      {
                        item?.title?.includes('Biolage') && (
                          <>
                            <img
                              alt="Biolage Badge"
                              style={{ width: '100px' }}
                              src={getStaticAssetPath('biolage.svg')}
                            />
                          </>
                        )
                      }

                      {/* If the title does not match any of the above, display the default IconAward */}
                      {
                        (!item?.title?.includes('NAMI Head Up Completion') &&
                        !item?.title?.includes('Purpose Discovery Completion') &&
                        !item?.title?.includes('Purpose Discovery/EQ 101') && 
                        !item?.title?.includes('Biolage') &&
                        !(item?.title === 'Beacon' && item?.issueYear === '2024')) &&
                        <img alt="" src={IconAward} />
                      }
                      <Tooltip title={item.title || "Title"}>
                        <Paragraph
                          className="title"
                        >
                          {item.title || "Title"}
                        </Paragraph>
                      </Tooltip>
                      <Tooltip title={getAwardText(item)}>
                        <Paragraph
                          ellipsis={{ rows: 1, expandable: true }}
                          className="issue"
                        >
                          {getAwardText(item)}
                        </Paragraph>
                      </Tooltip>
                    </>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
      <div
        className="ins-sec"
        hidden={!insName && PreviewMode.VIEW === mode}
        id="ins"
      >
        <div className="ins-top-sec">
          <div className="follow-wrap">
            <span className="follow">Follow me ON Instagram</span>
          </div>

          <div className="ins-name-sec">
            <span className="ins-name">{getInsDisplayName()}</span>
            <Button
              className="follow-btn btn-primary-light"
              onClick={() =>
                insName && window.open(`https://www.instagram.com/${insName}`)
              }
            >
              FOLLOW
            </Button>
          </div>
        </div>
        {!_.isEmpty(insPhotos) ? (
          <>
            {isMobile ? (
              <div className="carousel-wrap">
                <Carousel dots={false} centerMode={true} centerPadding="40px">
                  {RenderInsList(insPhotos, true)}
                </Carousel>
              </div>
            ) : (
              <div className="ins-list">{RenderInsList(insPhotos)}</div>
            )}
          </>
        ) : (
          <>
            {isMobile ? (
              <div className="carousel-wrap">
                <Carousel dots={false} centerMode={true} centerPadding="40px">
                  {_.map([0, 1, 2, 3], (num) => {
                    return (
                      <div className="ins-item-empty" key={num}>
                        <div className="center-wrap">
                          <img alt="" src={IconImageEmpty} />
                          <span>Instagram post</span>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            ) : (
              <div className="ins-list">
                {_.map([0, 1, 2, 3], (num) => {
                  return (
                    <div className="ins-item-empty" key={num}>
                      <div className="center-wrap">
                        <img alt="" src={IconImageEmpty} />
                        <span>Instagram post</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      {
        !customLocationCountMap.has(pageData.id) &&
        <div
          className="address-sec"
          id="components-anchor-location"
          hidden={PreviewMode.VIEW === mode && _.isEmpty(pageData.locations)}
        >
          <div className="address-list">
            <span className="canvas-title location-title">Locations</span>
            {_.map(
              _.isEmpty(pageData.locations)
                ? locationPh
                : (pageData.locations as []),
              (item, index) => {
                return (
                  <div
                    className={classnames("address-item", {
                      active: index === curLocation,
                    })}
                    key={index}
                    onClick={() => setCurLocation(index)}
                  >
                    <span className="number">{index + 1}</span>
                    <span className="value">
                      {item.location || `Address #${index + 1}`}
                    </span>
                  </div>
                );
              }
            )}
          </div>
          <div className="map-sec" id="map">
            <img alt="" src={PlaceHolderMap} hidden={!showMapPH} />
          </div>
        </div>
      }
      {
        mode === PreviewMode.VIEW && isDetail && (type === CanvasType.BUSINESS || CanvasType.SCHOOL) && <div className="similars-wrap" hidden={myJobs.length === 0}>
          <div className="similiar-title">Job Showcase</div>
          <Button onClick={handleViewAll} className="btn-secondary btn-view-all" > View all </Button>
          <div className="similiar-list-wrap">
            {myJobs.length &&
              myJobs.map((item: any, index: number) => {
                return renderJobsCard(item, index);
              })}
          </div>
        </div>
      }
      <div className="footer-media-sec" id="social-media">
        <div
          className="media-content"
          hidden={
            PreviewMode.VIEW === mode &&
            _.isEmpty(
              _.filter(pageData.socialMedias, (item: any) => {
                return !_.isEmpty(item.value);
              })
            )
          }
        >
          <span className="canvas-title">Follow us on social media</span>
          <div className="icon-list">
            {_.map(pageData.socialMedias, (social, index) => {
              return (
                <img
                  alt=""
                  src={getSocialIcon(social.label)}
                  key={index}
                  onClick={() => redirectToSocial(social)}
                />
              );
            })}
          </div>
        </div>

        <div className="media-divider" />

        <div className="made-with">
          Made with
          <span>
            <Link to={router_homepage_index}>BEAUTISTA</Link>
          </span>
        </div>
      </div>
      {!_.isEmpty(curPlayUrl) && (
        <VideoModal
          visible
          onClose={() => setCurPlayUrl("")}
          url={curPlayUrl}
        />
      )}
      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
      >
        <div className="send-msg-modal">
          <div className="common-title">Send Message</div>
          <div className="modal-text">You are sending a message via email.</div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          <div className="modal-text">
            Clicking on <strong>Send My Message</strong> will initiate a email
            that goes out to this user.
          </div>
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">
            Woohoo! Your message was sent successfully.
          </div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => setVisibleSuccessModal(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CanvasPreview;
