import React, { useEffect, useState, useCallback } from "react";
import { DatePicker, Button, Spin, Select, Row, Col, Tooltip } from "antd";
import moment, { Moment } from "moment";
import ChartComponent from "../../../components/component_generate_chart_multi";
import ChoroplethMap from "../../../components/component_choropleth_map";
import ReactTable from "../../../components/component_react_table";
import { getDataDashboard, getDetailedReportData, getMapData } from "../../../api/admin";
import * as XLSX from "xlsx";
import "./data.less";
import { IconButton } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';

const { Option } = Select;

interface DataItem {
  date: string;
  count: number;
}

interface DashboardData {
  [key: string]: DataItem[];
}

interface CombinedTableData {
  metric: string;
  [date: string]: number | string;
}

const AdminData: React.FC = () => {
  const [allUsers, setAllUsers] = useState<any>(null);
  const [stylistsAndStudents, setStylistsAndStudents] = useState<any>(null);
  const [schools, setSchools] = useState<any>(null);
  const [businesses, setBusinesses] = useState<any>(null);
  const [finance, setFinance] = useState<any>(null);
  const [brands, setBrands] = useState<any>(null);
  const [chartData, setChartData] = useState<DashboardData | null>(null);
  const [intervalNonChart, setIntervalNonChart] = useState<any>({ AllUsers: {}, StylistsAndStudents: {}, Schools: {}, Businesses: {}, Finance: {}, Brands: {} });
  const [nonIntervalData, setNonIntervalData] = useState<any>({ AllUsers: {}, StylistsAndStudents: {}, Schools: {}, Businesses: {}, Finance: {}, Brands: {} });
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(3, "month"));
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [choroplethData, setChoroplethData] = useState<any>(null);
  const [visibleTables, setVisibleTables] = useState<{ [key: string]: boolean }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [groupBy, setGroupBy] = useState<string>('MONTH');
  const [tooltips, setTooltips] = useState<any>(null);

  useEffect(() => {
    const fetchMapData = async () => {
      const mapData = await getMapData();
      setChoroplethData(mapData);
    };
    fetchMapData();
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);

    let adjustedStartDate = startDate.clone();
    let adjustedEndDate = endDate.clone();

    switch (groupBy) {
      case 'MONTH':
        adjustedStartDate = adjustedStartDate.startOf('month');
        adjustedEndDate = adjustedEndDate.endOf('month');
        break;
      case 'YEAR':
        adjustedStartDate = adjustedStartDate.startOf('year');
        adjustedEndDate = adjustedEndDate.endOf('year');
        break;
      case 'QUARTER':
        adjustedStartDate = adjustedStartDate.startOf('quarter');
        adjustedEndDate = adjustedEndDate.endOf('quarter');
        break;
      default:
        break;
    }

    const interval = {
      startDate: adjustedStartDate.format("YYYY-MM-DD"),
      endDate: adjustedEndDate.format("YYYY-MM-DD"),
      groupBy,
    };

    console.log(interval);

    const response = await getDataDashboard(interval);

    setAllUsers(response.chartData.AllUsers || {})
    setStylistsAndStudents(response.chartData.StylistsAndStudents || {});
    setSchools(response.chartData.Schools || {});
    setBusinesses(response.chartData.Businesses || {});
    setFinance(response.chartData.Finance || {});
    setBrands(response.chartData.Brands || {});
    setTooltips(response.tooltips);

    setChartData({
      ...response.chartData.AllUsers,
      ...response.chartData.StylistsAndStudents,
      ...response.chartData.Schools,
      ...response.chartData.Businesses,
      ...response.chartData.Finance,
      ...response.chartData.Brands
    });

    setIntervalNonChart(response.intervalNonChart)
    setNonIntervalData(response.nonIntervalData);

    setLoading(false);
  }, [startDate, endDate, groupBy]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleStartDateChange = (date: Moment | null) => {
    if (date) {
      if (groupBy === 'MONTH') {
        setStartDate(date.startOf('month'));
      } else if (groupBy === 'YEAR') {
        setStartDate(date.startOf('year'));
      } else {
        setStartDate(date);
      }
    }
  };

  const handleEndDateChange = (date: Moment | null) => {
    if (date) {
      if (groupBy === 'MONTH') {
        setEndDate(date.endOf('month'));
      } else if (groupBy === 'YEAR') {
        setEndDate(date.endOf('year'));
      } else {
        setEndDate(date);
      }
    }
  };

  const renderDatePicker = (label: string, value: Moment, onChange: (date: Moment | null) => void, isEndDate: boolean) => {
    if (groupBy === 'MONTH') {
      return (
        <DatePicker
          value={value}
          onChange={onChange}
          format="MMM/YYYY"
          mode="month"
          onPanelChange={(value) => {
            if (isEndDate) {
              onChange(value.endOf('month'));
            } else {
              onChange(value.startOf('month'));
            }
          }}
          style={{ marginRight: 10 }}
        />
      );
    } else if (groupBy === 'YEAR') {
      return (
        <DatePicker
          value={value}
          onChange={onChange}
          format="YYYY"
          mode="year"
          onPanelChange={(value) => onChange(isEndDate ? value.endOf('year') : value.startOf('year'))}
          style={{ marginRight: 10 }}
        />
      );
    } else {
      return (
        <DatePicker
          value={value}
          onChange={onChange}
          format="MM/DD/YYYY"
          style={{ marginRight: 10 }}
        />
      );
    }
  };

  const renderQuarterPicker = (value: Moment, onChange: (date: Moment | null) => void) => {
    const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
    const years = Array.from({ length: 10 }, (_, i) => moment().year() - 5 + i); // Last 5 years, current year, and next 4 years

    const handleQuarterChange = (quarter: string) => {
      const quarterMonth = (parseInt(quarter.replace('Q', '')) - 1) * 3 + 1;
      onChange(moment(value).month(quarterMonth - 1).startOf('month'));
    };

    const handleYearChange = (year: string) => {
      onChange(moment(value).year(parseInt(year)));
    };

    return (
      <Row gutter={8} style={{ marginRight: 10 }}>
        <Col>
          <Select value={`Q${value.quarter()}`} onChange={handleQuarterChange}>
            {quarters.map((q) => (
              <Option key={q} value={q}>
                {q}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select value={String(value.year())} onChange={handleYearChange}>
            {years.map((y) => (
              <Option key={y} value={String(y)}>
                {y}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  };

  const camelCaseToSpaceSeparated = (str: string) => {
    return str.replace(/([A-Z0-9])/g, ' $1').replace(/^./, (char) => char.toUpperCase());
  };

  const prepareChartDatasets = () => {
    if (!chartData) return [];

    return Object.keys(chartData).map((report) => {
      const filteredData = chartData[report] || [];

      const dataset = filteredData.map((item) => {
        let dateLabel = item.date;

        if (groupBy === 'QUARTER') {
          // Convert "Q1 2022" into "2022-01-01" for Chart.js to understand
          const [quarter, year] = item.date.split(' ');
          const month = (parseInt(quarter.replace('Q', '')) - 1) * 3 + 1; // Q1 -> 1, Q2 -> 4, Q3 -> 7, Q4 -> 10
          dateLabel = `${year}-${String(month).padStart(2, '0')}-01`;
        } else if (groupBy === 'WEEK') {
          // Convert "Jul/28/24 - Aug/03/24" to "2024-07-28" for Chart.js to understand
          const startDate = item.date.split(' - ')[0];
          dateLabel = moment(startDate, "MMM/DD/YY").format("YYYY-MM-DD");
        }

        return {
          x: dateLabel,
          y: item.count
        };
      });

      return {
        label: camelCaseToSpaceSeparated(report),
        data: dataset,
        borderColor: `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`,
        backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`,
      };
    });
  };

  const prepareMetricsData = (
    chartData: DashboardData,
    dateSeries: string[],
  ): { [key: string]: { [key: string]: number } } => {
    const metricsData: { [key: string]: { [key: string]: number } } = {};

    Object.keys(chartData).forEach((report) => {
      const filteredData = chartData[report] || [];
      const dataMap = new Map(
        filteredData.map((item) => [item.date, item.count])
      );

      metricsData[report] = dateSeries.reduce((acc, date) => {
        acc[date] = dataMap.get(date) || 0;
        return acc;
      }, {} as { [key: string]: number });
    });

    return metricsData;
  };

  const prepareCombinedTableData = (
    metricsData: { [key: string]: { [key: string]: number } },
    dateSeries: string[]
  ): CombinedTableData[] => {
    return Object.keys(metricsData).map((metric) => {
      const rowData: CombinedTableData = { metric: keyToTitleCase(metric) };

      dateSeries.forEach((date) => {
        rowData[date] = metricsData[metric][date];
      });

      return rowData;
    });
  };

  const tableData = (data: any): CombinedTableData[] => {
    if (!chartData) return [];

    const dateSeries = generateDateSeries(startDate, endDate, groupBy);
    const metricsData = prepareMetricsData(data, dateSeries);
    return prepareCombinedTableData(metricsData, dateSeries);
  };

  const generateDateSeries = (startDate: Moment, endDate: Moment, unit: string): string[] => {
    const dateSeries: string[] = [];
    let currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      switch (unit) {
        case 'DAY':
          dateSeries.push(currentDate.format("MMM/DD/YY"));
          currentDate.add(1, 'day');
          break;
        case 'WEEK':
          // Adjust to get the Sunday of the current week
          const sunday = currentDate.clone().startOf('week');
          const saturday = sunday.clone().endOf('week');
          dateSeries.push(`${sunday.format("MMM/DD/YY")} - ${saturday.format("MMM/DD/YY")}`);
          currentDate.add(1, 'week');
          break;
        case 'MONTH':
          dateSeries.push(currentDate.format("MMM/YYYY"));
          currentDate.add(1, 'month');
          break;
        case 'QUARTER':
          dateSeries.push(`Q${currentDate.quarter()} ${currentDate.format("YYYY")}`);
          currentDate.add(1, 'quarter');
          break;
        case 'YEAR':
          dateSeries.push(currentDate.format("YYYY"));
          currentDate.add(1, 'year');
          break;
        default:
          break;
      }
    }

    return dateSeries;
  };

  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const exportToCSV = () => {
    const data = tableData(chartData);
    if (data.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Metric," + Object.keys(data[0]).filter(key => key !== 'metric').join(",") + "\n";

    data.forEach(row => {
      const metricValue = row.metric;
      const rowValues = Object.keys(row)
        .filter(key => key !== 'metric')
        .map(key => row[key]);
      csvContent += `${metricValue},${rowValues.join(",")}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `CM_REPORT_${formatDate(startDate)}-${formatDate(endDate)}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click(); // Trigger download
    document.body.removeChild(link); // Clean up
  };

  const exportTableDataToXLSX = (tableData: any, filename: string) => {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, filename);
  };

  const exportChoroplethData = (data: any, filename: string) => {
    const ws = XLSX.utils.json_to_sheet(data.map((item: any) => ({ state: item.state, count: item.count })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Map Data");
    XLSX.writeFile(wb, filename);
  };

  const exportAllData = (key: string, type: 'table' | 'map', data: any) => {
    let filename = `${keyToTitleCase(key)}_${formatDate(startDate)}-${formatDate(endDate)}.xlsx`;
    if (type === 'table') {
      exportTableDataToXLSX(data, filename);
    } else if (type === 'map') {
      exportChoroplethData(data, filename);
    }
  };

  const toggleTableVisibility = (key: string) => {
    setVisibleTables((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const exportDetailedReport = async (report: string, cat: string) => {
    const interval = {
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    };

    try {
      const response = await getDetailedReportData(titleToKeyCase(report), cat, interval);
      if (response.length === 0) {
        console.warn("No data to export for this report.");
        return;
      }
      let csvContent = "data:text/csv;charset=utf-8,";
      const headers = Object.keys(response[0]);
      csvContent += headers.join(",") + "\n";
      response.forEach(row => {
        const rowValues = headers.map(header => row[header]);
        csvContent += rowValues.join(",") + "\n";
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${report}_DETAILED_REPORT_${formatDate(startDate)}-${formatDate(endDate)}.csv`
      );
      document.body.appendChild(link); // Required for FF
      link.click(); // Trigger download
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error("Failed to export detailed report:", error);
    }
  };

  const groupedChoroplethData = choroplethData
    ? choroplethData.reduce((acc: any, item: any) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {} as { [key: string]: { state: string; count: number }[] })
    : null;

  const keyToTitleCase = (key: string) => {
    return key
      .replace(/([A-Z])/g, ' $1')
      .replace(/(\d)/, ' $1')
      .replace(/\s+/g, ' ')
      .trim()
      .replace(/^./, (str) => str.toUpperCase());
  };

  const titleToKeyCase = (title: string) => {
    return title
      .replace(/\s+(.)/g, (_, char) => char.toUpperCase())
      .replace(/\s+/g, '')
      .replace(/^./, (str) => str.toLowerCase());
  };

  const formatDateRange = (startDate: Moment, endDate: Moment, groupBy: string) => {
    switch (groupBy) {
      case 'DAY':
      case 'WEEK':
        return `${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`;
      case 'MONTH':
        return `${startDate.format('MMM YYYY')} - ${endDate.format('MMM YYYY')}`;
      case 'QUARTER':
        return `Q${startDate.quarter()} ${startDate.format('YYYY')} - Q${endDate.quarter()} ${endDate.format('YYYY')}`;
      case 'YEAR':
        return `${startDate.format('YYYY')} - ${endDate.format('YYYY')}`;
      default:
        return '';
    }
  };

  return (
    <div className="view-admin-data-dashboard">
      <h1>KPI Dashboard</h1>
      <div className="selectors">
        {groupBy === 'QUARTER'
          ? renderQuarterPicker(startDate, handleStartDateChange)
          : renderDatePicker('Start Date', startDate, handleStartDateChange, false)}
        {groupBy === 'QUARTER'
          ? renderQuarterPicker(endDate, handleEndDateChange)
          : renderDatePicker('End Date', endDate, handleEndDateChange, true)}
        <Select
          value={groupBy}
          onChange={(value) => setGroupBy(value)}
          style={{ width: 150 }}
        >
          <Option value="DAY">Day</Option>
          <Option value="WEEK">Week</Option>
          <Option value="MONTH">Month</Option>
          <Option value="QUARTER">Quarter</Option>
          <Option value="YEAR">Year</Option>
        </Select>
        <Button onClick={exportToCSV}>Export to CSV</Button>
      </div>
      <div className="combined-table-container">
        {loading ? (
          <div className="spinner">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {allUsers &&
              <ReactTable
                data={tableData(allUsers)}
                onMetricClick={(metric) => exportDetailedReport(metric, 'AllUsers')}
                metricHeaderName={`All Users`}
                tooltip={tooltips}
              />
            }
            <div className="bubble-container">
              {Object.entries(intervalNonChart.AllUsers || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)} {formatDateRange(startDate, endDate, groupBy)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="bubble-container">
              {Object.entries(nonIntervalData.AllUsers || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {schools &&
              <ReactTable
                data={tableData(schools)}
                onMetricClick={(metric) => exportDetailedReport(metric, 'Schools')}
                metricHeaderName={`Schools`}
                tooltip={tooltips}
              />
            }
            <div className="bubble-container">
              {Object.entries(intervalNonChart.Schools || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)} {formatDateRange(startDate, endDate, groupBy)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="bubble-container">
              {Object.entries(nonIntervalData.Schools || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {businesses &&
              <ReactTable
                data={tableData(businesses)}
                onMetricClick={(metric) => exportDetailedReport(metric, 'Businesses')}
                metricHeaderName={`Businesses`}
                tooltip={tooltips}
              />
            }
            <div className="bubble-container">
              {Object.entries(intervalNonChart.Businesses || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)} {formatDateRange(startDate, endDate, groupBy)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="bubble-container">
              {Object.entries(nonIntervalData.Businesses || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {brands &&
              <ReactTable
                data={tableData(brands)}
                onMetricClick={(metric) => exportDetailedReport(metric, 'Brands')}
                metricHeaderName={`Brands`}
                tooltip={tooltips}
              />
            }
            <div className="bubble-container">
              {Object.entries(intervalNonChart.Brands || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)} {formatDateRange(startDate, endDate, groupBy)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="bubble-container">
              {Object.entries(nonIntervalData.Brands || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {finance &&
              <ReactTable
                data={tableData(finance)}
                onMetricClick={(metric) => exportDetailedReport(metric, 'Finance')}
                metricHeaderName={`Finance`}
                tooltip={tooltips}
              />
            }
            <div className="bubble-container">
              {Object.entries(intervalNonChart.Finance || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)} {formatDateRange(startDate, endDate, groupBy)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="bubble-container">
              {Object.entries(nonIntervalData.Finance || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {stylistsAndStudents &&
              <ReactTable
                data={tableData(stylistsAndStudents)}
                onMetricClick={(metric) => exportDetailedReport(metric, 'StylistsAndStudents')}
                metricHeaderName={`Stylist & Students`}
                tooltip={tooltips}
              />
            }
            <div className="bubble-container">
              {Object.entries(intervalNonChart.StylistsAndStudents || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)} {formatDateRange(startDate, endDate, groupBy)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="bubble-container">
              {Object.entries(nonIntervalData.StylistsAndStudents || {}).map(([key, data]) => (
                <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
                  <h1 onClick={() => toggleTableVisibility(key)}>
                    {keyToTitleCase(key)}
                    {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                    {tooltips && (
                      <>
                        <Tooltip
                          title={
                            tooltips.find(
                              (t: any) => t.name === key
                            )?.tooltip || null
                          }
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Button
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() => exportAllData(key, 'table', data)}
                    >
                      Export
                    </Button>
                  </h1>
                  {visibleTables[key] && (
                    <div className="react-table-container">
                      <ReactTable data={data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {loading ? (
        <div className="spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="chart-container">
            <ChartComponent datasets={prepareChartDatasets()} timeUnit={groupBy.toLowerCase() as 'day' | 'week' | 'month' | 'quarter' | 'year'} />
          </div>
        </>
      )}
      {groupedChoroplethData ? (
        <div className="bubble-container">
          {Object.entries(groupedChoroplethData).map(([key, data]) => (
            <div key={key} className={`bubble ${visibleTables[key] ? 'full-width' : ''}`}>
              <h1 onClick={() => toggleTableVisibility(key)}>
                {keyToTitleCase(key)} Canvas Locations
                {Array.isArray(data) && data.length > 1 && ` (${data.length})`}
                <Button
                  size="small"
                  style={{ marginLeft: 10 }}
                  onClick={() => exportAllData(key, 'table', data)}
                >
                  Export
                </Button>
              </h1>
              {visibleTables[key] && (
                <div className="choropleth-map-container">
                  <ChoroplethMap data={data} label={`${key.toUpperCase()} Canvas Locations`} />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="spinner">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default AdminData;
