import React, { Fragment, useState } from "react";
import { Layout, Button, Icon } from "antd";
import _ from "lodash";
import classnames from "classnames";
import Logo from "../assets/images/logo.svg";
import IconCheck from "../assets/images/ic_step_grey.svg";
import IconView from "../assets/images/ic_view.svg";
import IconCheckGreen from "../assets/images/ic_check_green.svg";
import { CanvasType } from "../utils/enum";
import "./component_create_header.less";

const { Header } = Layout;

const itemList: any = {
  [CanvasType.BUSINESS]: [
    "Company Information",
    "Business Canvas",
    "Preview",
    "Publish",
  ],
  [CanvasType.SCHOOL]: [
    "School Information",
    "School Canvas",
    "Preview",
    "Publish",
  ],
  [CanvasType.STUDENT]: ["Student Canvas", "Preview", "Publish"],
  [CanvasType.PROFESSIONAL]: ["Professional Canvas", "Preview", "Publish"],
};

interface Props {
  type: string;
  className?: string;
  step?: number;
  onPreviewClick?: Function;
  onEditClick?: Function;
  onPublishClick?: Function;
  onSaveDraftClick?: Function;
  showSave?: boolean;
  jumpToStep?: Function;
  showAutosaveHint?: boolean;
  hideProgressItems?: boolean;
}

interface ItemProps {
  step: number;
  curStep: number;
  text: string;
  jumpToStep?: Function;
}

const ProgressItem: React.FC<ItemProps> = (props) => {
  const { step, curStep, text, jumpToStep } = props;
  return (
    <div
      className={classnames("progress-item")}
      onClick={() => {
        if (curStep !== step && step < 2 && jumpToStep) {
          jumpToStep(step);
        }
      }}
    >
      {curStep > step ? (
        <img
          alt=""
          src={IconCheck}
          className={classnames("ic-step", { isHeighLight: step === curStep })}
        />
      ) : (
        <span
          className={classnames("circle", { isHeighLight: step === curStep })}
        />
      )}
      <span
        className={classnames("item-text", {
          isHeighLight: step === curStep,
          pointer: step < 2,
        })}
      >
        {text}
      </span>
    </div>
  );
};

const CreateBusinessCanvasHeader: React.FC<Props> = (props) => {
  const [saving, setSaving] = useState(false)
  const {
    type,
    className,
    step = 0,
    onPreviewClick,
    onEditClick,
    onPublishClick,
    onSaveDraftClick,
    showSave = false,
    jumpToStep,
    showAutosaveHint,
    hideProgressItems
  } = props;
  return (
    <Header className={classnames("create-header-root", className)}>
      <div className="header-wrap">
        <img alt="BEAUTISTA-logo" src={Logo} className="logo" />
        <div className="operate-sec">
          <div className="saved" hidden={!showSave}>
            <img src={IconCheckGreen} alt="" className="check-green" />
            Saved
          </div>
          {showAutosaveHint && <span className="autosave-hint">Auto-saved every 20 seconds.</span>}
          <Button
            className="btn-draft btn-secondary"
            // onClick={_.throttle(_.debounce(() => {
            //   onSaveDraftClick && onSaveDraftClick()
            // },1000), 3000)}
            disabled={saving}
            onClick={e => {
              if (onSaveDraftClick) {
                setSaving(true)
                onSaveDraftClick()

                setTimeout(()=> {
                  setSaving(false)
                }, 6000)
              }
            }}
            hidden={
              type === CanvasType.BUSINESS || type === CanvasType.SCHOOL
                ? step === 0 || step === 3
                : step === 2
            }
          >
            <Icon
              type="save"
              style={{ fontSize: "18px" }}
              className="icon-view"
            />
            <span className="txt-preview">Save and close</span>
          </Button>
          <Button
            className="btn-preview btn-secondary"
            onClick={() => onPreviewClick && onPreviewClick()}
            hidden={
              type === CanvasType.BUSINESS || type === CanvasType.SCHOOL
                ? step !== 1
                : step !== 0
            }
          >
            <img alt="" src={IconView} className="icon-view" />
            <span className="txt-preview">Preview</span>
          </Button>
          <Button
            className="btn-edit btn-secondary"
            onClick={() => onEditClick && onEditClick()}
            hidden={
              type === CanvasType.BUSINESS || type === CanvasType.SCHOOL
                ? step !== 2
                : step !== 1
            }
          >
            Edit
          </Button>
          <Button
            onClick={() => onPublishClick && onPublishClick()}
            className="btn-publish btn-primary"
            hidden={
              type === CanvasType.BUSINESS || type === CanvasType.SCHOOL
                ? step === 0 || step === 3
                : step === 2
            }
          >
            Publish
          </Button>
        </div>
      </div>
      {
        !hideProgressItems &&
      <div className="progress">
        {_.map(itemList[type], (item, index: number) => {
          return (
            <Fragment key={index}>
              <ProgressItem
                step={index}
                curStep={step}
                text={item}
                jumpToStep={jumpToStep}
              />
              {index < itemList[type].length - 1 ? (
                <span className="stash" />
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </div>
      }
    </Header>
  );
};

export default CreateBusinessCanvasHeader;
