import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Avatar, Button, Icon, Input, List, message, Modal, Spin } from "antd";
import { TextField, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import {
  ListTable,
  IHeadItem,
  IBodyItem,
} from "../../../components/component_list_table";

import { GoogleUtil } from "../../../utils/utils";

//getCustomized
import { getCustomized } from "../../../api/Options";
import {
  canvasList,
  getDataCanvas,
  getAccessAccount,
  getGroupList,
  delGroup,
  updateGroup,
  createGroup,
} from "../../../api/admin";

import { CanvasType, router_business_canvas, router_canvas_detail, router_professional_canvas, router_school_canvas, router_student_canvas } from "../../../utils/enum";
import SearchComponent from "../../homepage/SearchComponent";
import IconMessageBlack from "../../../assets/images/ic_message_black.svg";
import IconPrint from "../../../assets/images/ic_link.svg";
import IconCalendar from "../../../assets/images/ic_calendar.svg";
import ic_filter from "../../../assets/images/ic_filter.svg";
import ic_export from "../../../assets/images/ic_export.svg";
import ic_close from "../../../assets/images/ic_close_black.svg";

import "./canvas.less";

import { mapProp, doAction } from "module-reaction";
import { modle_user, LoginWithTokenAction } from "../../../models/model_user";
import { setUserLoginInfoAction } from "../../../models/model_home";
import { browse_canvas_filter_options, getFilterNum, getFilterNumDot } from "../../../models/model_homepage_canvases";
import { awardOpts } from "../../../components/component_add_award_modal";
import { type } from "os";

const blank_all = 'all'
const emptyOpt = {
  name: 'All',
  value: blank_all,
  key: blank_all,
}
const radiusList: any[] = [
  {
    value: 10,
    name: "10 miles",
  },
  {
    value: 25,
    name: "25 miles",
  },
  {
    value: 30,
    name: "30 miles",
  },
  {
    value: 50,
    name: "50 miles",
  },
  {
    value: 100,
    name: "100 miles",
  },
  {
    value: 250,
    name: "250 miles",
  },
];

const attendanceOpts = [
  emptyOpt,
  {
    key: 'attendance-0-20',
    name: '0 - 20%'
  },
  {
    key: 'attendance-20-50',
    name: '20% - 50%'
  },
  {
    key: 'attendance-50-80',
    name: '50% - 80%'
  },
  {
    key: 'attendance-80-100',
    name: '80% - 100%'
  },
]
const gpaOpts = [
  emptyOpt,
  {
    key: 'gpa-0-20',
    name: '0 - 20'
  },
  {
    key: 'gpa-20-50',
    name: '20 - 50'
  },
  {
    key: 'gpa-50-80',
    name: '50 - 80'
  },
  {
    key: 'gpa-80-100',
    name: '80 - 100'
  },
]

const canvasOpts: any[] = [
  {
    value: "student",
    name: "Student",
  },
  {
    value: "professional",
    name: "Professional",
  },
  {
    value: "business",
    name: "Business",
  },
  {
    value: "school",
    name: "School",
  },
];

const classList: any[] = [
  emptyOpt,
  {
    value: "dayAndNight",
    name: "Both",
  },
  {
    value: "day",
    name: "Day",
  },
  {
    value: "night",
    name: "Night",
  },
];

const Canvas: React.FC<Props> = (props: Props) => {
  const { history } = props;

  const [showName, setShowName] = useState(false);

  const tHeadRender: IHeadItem[] = [
    showName
    ? { name: "Name", key: "busName" }
    : { name: "User's name", isSort: true, key: "name" },
    { name: "Phone", key: "phone" },
    { name: "Created on", isSort: true, key: "createdAt" },
    { name: "Canvas type", key: "type" },
    { name: "Views", key: "views" },
    { name: "Status", key: "status" },
    { name: "Actions" },
  ];

  const tBodyRender: IBodyItem[] = [
    {
      type: "avatar",
      component: (row: any) => (
        // <div className="avatar-name-inner">
        <>
          <Avatar
            icon="user"
            src={row && row.avatar}
            className="avatar"
            style={{ marginRight: "8px" }}
          />
          {showName ? row.name : row.userName}
          </>
        // </div>
      ),
      className: "avatar-name",
    },
    { name: "Phone", key: "phone" },
    { name: "Created on", key: "createdAt", type: "date" },
    { name: "Canvas type", key: "type", className: "capitalize" },
    { name: "Views", key: "views", className: "capitalize" },
    {
      name: "Status",
      key: "status",
      className: "status-cell",
      component: (row: any) => {
        if (row?.status === "public") {
          return <span>active</span>;
        } else {
          return <span className="active">inactive</span>;
        }
      },
    },  
    {
      type: "dropdown-actions",
      className: "drop-actions",
      dropdownList: [
        {
          icon: IconPrint,
          name: "Edit Canvas",
          onClick: (item: any, index: number) => changeAuthority(item),
        },
        {
          icon: IconMessageBlack,
          name: "View canvas",
          onClick: (item: any, index: number) =>
            history.push(router_canvas_detail.replace(":id", item.slug || item.id)),
        },
      ],
    },
  ];

  const size = 8;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [dataList, setDatalist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showManageGroup, setShowManageGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(null as any);

  const [selectList, setSelectList] = useState([] as any);
  const [sortBy, setSortBy] = useState("");
  const [options, setOptions] = useState<any>();
  const [groupList, setGroupList] = useState([] as any)
  const [groupFresh, setGroupFresh] = useState(1)
  const [dataItem, setDataItem] = useState({
    viewsOnAllCanvases: 0,
    activeProfiles: 0,
  });
  const initSearch = {
    keywords: null,
    radius: 30,
    supportedProgram: 0,
    software: 0,
    isAccredited: "all",
    classType: "all",
    type: "student",
    group: blank_all,
    startDate: null,
    endDate: null,
  }
  const [search, setSearch] = useState<any>({ ...initSearch });
  const list = (page = 1) => {
    const {
      keywords,
      addressInfo,
      radius,
      location,
      ...others
    } = search;
    let _data = {
      keyword: keywords,
      location: addressInfo
        ? {
          radius: radius * 1609.344,
          coordinates: addressInfo?.coordinates || null,
        }
        : null,
      page,
      size,
      sortBy: sortBy || null,
      ...(Object.keys(others).reduce((obj, key) => {
        const v = others[key]
        // all=> null
        // 'num,num' => [num, num]
        obj[key] = v === blank_all ? null : (typeof v === 'string' && v.includes(',') ? v.split(',').map(_ => parseInt(_)) : v)
        if (v === 'true') {
          obj[key] = true
        }else if (v === 'false') {
          obj[key] = false
        }
        return obj
      }, {} as any))
    };
    setLoading(true);
    canvasList(_data).then((data) => {
      setDatalist(data.rows);
      setTotal(data.total);
      setLoading(false);
    });
    getData(_data);
    if(search.type == 'business' || search.type == 'school'){
      setShowName(true) 
    }else{
      setShowName(false)
    }
  };

  const getData = (_data: any) => {
    getDataCanvas(_data).then((data) => {
      setDataItem(data);
    });
  };

  const freshGroupOpts = (keyword?: string) => {
    getGroupList(keyword).then(data => {
      setGroupList([emptyOpt].concat(data?.groups?.map((g: any) => ({ value: g.id, name: g.name })) || []))
      setGroupFresh(groupFresh + 1)
    })
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(1);
    list(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    list(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy]);

  useEffect(() => {
    let _data = {
      kind: "official",
      types: [
        "program",
        "brand",
        "software",
        "speciality",
        "companyBenefit",
        "charity",
        "school",
        "state",
        "category",
        "paymentMode",
      ],
    };
    getCustomized(true, _data.types).then((data) => {
      const category = data.category
      data.category = category.business // only use business category
      // sort options by alphabet
      for(const k in data) {
        const opts = data[k]
        if (Array.isArray(opts)) {
          opts.sort((a,b) => a.name.toUpperCase().charCodeAt(0) - b.name.toUpperCase().charCodeAt(0))
        }
      }
      setOptions(data);
    });
    freshGroupOpts()
  }, []);

  //link normal account - access account
  const changeAuthority = (item: any) => {
    let _data = {
      id: item.userId,
    };
    getAccessAccount(_data).then((data: any) => {
      sessionStorage.setItem('canvas:local:adminpath', props.location.pathname)

      doAction(LoginWithTokenAction);
      doAction(setUserLoginInfoAction, {
        res: data,
        history: props.history,
        fromPage: getEditCanvasPath(item)
      });
    });
  };

  const getEditCanvasPath = (item: any) => {
    let path
    switch (item.type) {
      case CanvasType.BUSINESS:
        path = router_business_canvas.replace(':id', item.id)
        break;
      case CanvasType.SCHOOL:
        path = router_school_canvas.replace(':id', item.id)
        break;
      case CanvasType.STUDENT:
        path = router_student_canvas.replace(':id', item.id)
        break;
      case CanvasType.PROFESSIONAL:
      default:
        path = router_professional_canvas.replace(':id', item.id)
        break;
    }
    return path
  }

  //dialog-search
  const [dialog, setDialog] = useState(false);
  const handleShow = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  const resetData = () => {
    setSearch({ ...initSearch })
    setSelectList([])
  }

  //export
  const handleExport = () => {
    const {
      keywords,
      addressInfo,
      radius,
      location,
      ...others
    } = search;
    let _data = {
      keyword: keywords,
      location: addressInfo
        ? {
          radius: radius * 1609.344,
          coordinates: addressInfo?.coordinates || null,
        }
        : null,
      page,
      size,
      sortBy: sortBy || null,
      ...(Object.keys(others).reduce((obj, key) => {
        const v = others[key]
        // all=> null
        // 'num,num' => [num, num]
        obj[key] = v === blank_all ? null : (typeof v === 'string' && v.includes(',') ? v.split(',').map(_ => parseInt(_)) : v)
        return obj
      }, {} as any))
    };

    let str = Object.keys(_data)
      .filter((item: any) => _data[item])
      .map(
        (prev: any) =>
          prev +
          "=" +
          encodeURIComponent(
            (typeof _data[prev] === "object" && JSON.stringify(_data[prev])) ||
            _data[prev]
          )
      )
      .join("&");

    window.open(
      decodeURIComponent(
        process.env.REACT_APP_BASE_URL + "/admin/canvas/export-to-csv?" + str
      )
    );
  };

  const createNewGroup = (name: string) => {
    createGroup(name, selectList.map((id: number) => {
      const item: any = dataList.find((it: any) => it.id === id)
      return {
        userId: item!.userId,
        canvasId: item!.id
      }
    })).then(res => {
      if (res?.success) {
        message.info('group created success')
        setSelectList([])
        freshGroupOpts()
      }
      setShowCreateGroup(false)
    })
  }

  const handleEditGroup = (item: any) => {
    setEditGroup(item)
    setShowManageGroup(false)
  }
  const handelDelGroup = (item: any) => {
    delGroup(item.id).then(res => {
      if (res?.success) {
        message.info('delete group success!')
      }
      setShowManageGroup(false)
      freshGroupOpts()
    })
  }
  const saveEditGroup = (name: string) => {
    updateGroup(editGroup.id, name).then(res => {
      if (res?.success) {
        message.info('update group success!')
      }
      setEditGroup(null)
      // fresh group list
      freshGroupOpts()
    })
  }

  return (
    <div className="admin-canvas-container">
      <div className="title">
        Canvas Dashboard
        <span className="title-btn"></span>

      </div>
      <div className="list-wrapper">
        <div className="search-item">
          <SearchComponent
            placeholder="Search by Name"
            value={{
              location: search.location,
              keywords: search.keywords,
            }}
            autoCompleteService={GoogleUtil.googleAutoCompleteService}
            onChange={(val: any) => {
              setSearch({
                ...search,
                ...val,
              });
            }}
          />
          <div className="date-pickers">

            <div className="date-sec">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/DD/YY"
                  label="Start Date"
                  className="material-date-picker date-input"
                  views={["year", "month", "date"]}
                  value={search.startDate}
                  onChange={(value) => {
                    setSearch({
                      ...search,
                      startDate: value?.format("MM/DD/YY"),
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
              <img alt="" src={IconCalendar} />
            </div>
            <div className="date-sec ml-24">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/DD/YY"
                  label="End Date"
                  className="material-date-picker date-input"
                  views={["year", "month", "date"]}
                  value={search.endDate}
                  onChange={(value) => {
                    setSearch({
                      ...search,
                      endDate: value?.format("MM/DD/YY"),
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
              <img alt="" src={IconCalendar} />
            </div>
          </div>

        </div>
        <div className="search-item mt-16">
          <TextField
            label="CANVAS TYPE"
            variant="filled"
            select
            className="material-input input redius-wrapper"
            value={search.type}
            onChange={(e) =>
              setSearch({
                ...initSearch, // change canvas type will clear other filters
                type: e.target.value,
              })
            }
          >
            {canvasOpts.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="RADIUS"
            variant="filled"
            select
            className="material-input input ml-24 redius-wrapper"
            value={search.radius}
            onChange={(e) =>
              setSearch({
                ...search,
                radius: e.target.value,
              })
            }
          >
            {radiusList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="GROUP NAME"
            variant="filled"
            select
            className="material-input input ml-24 redius-wrapper"
            value={search.group}
            onChange={(e) =>
              setSearch({
                ...search,
                group: parseInt(e.target.value),
              })
            }
          >
            {groupList.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <div className="btn-item ml-24">
            <Button
              disabled={!options?.program}
              className="item filter-btn"
              onClick={handleShow}>
              <img src={ic_filter} alt="filter" />
              More Filters
            </Button>
          </div>
        </div>
        <div className="btn-item mt-16">
          <Button className="item filter-btn" onClick={handleExport}>
            <img src={ic_export} alt="filter" />
              Export csv
        </Button>
          <Button
            className="item ml-24 filter-btn"
            disabled={selectList.length === 0}
            onClick={e => setShowCreateGroup(true)}>
            <img src={ic_export} alt="filter" />
              Add to group
        </Button>
          <Button className="item ml-24 filter-btn" onClick={e => setShowManageGroup(true)}>
            <img src={ic_export} alt="filter" />
              Manage group
        </Button>
          <Button
            className="item ml-24 filter-btn clear-btn"
            onClick={resetData}
          >
            <img src={ic_close} alt="filter" />
                CLEAR SEARCH
              </Button>
        </div>
        <div className="data-item">
          <span>
            <i>Number of canvases</i>
            {total}
          </span>
          <span>
            <i>Active portfolios</i>
            {dataItem.activeProfiles}
          </span>
          <span>
            <i>Views on all canvases</i>
            {dataItem.viewsOnAllCanvases}
          </span>
        </div>
        <div className="list-item">
          <ListTable
            tHeadRender={tHeadRender}
            tBodyRender={tBodyRender}
            loading={loading}
            hasSelect={true}
            idKey="id"
            dataList={dataList}
            selectList={selectList}
            selectOnChange={setSelectList}
            total={total}
            size={8}
            page={page}
            pageOnChange={handleChangePage}
            orderBy={sortBy}
            orderByOnChange={(value: string) => setSortBy(value)}
          />
        </div>
      </div>
      <FilterModal
        classes="common-filter"
        visible={dialog}
        onClose={handleDialogClose}
        options={options}
        canvasGroups={groupList}
        searchValue={search}
        onChange={(e: any) => {
          setSearch({
            ...search,
            ...e,
          });
        }}
      />
      <CreateGroupModal
        visible={showCreateGroup}
        onClose={() => setShowCreateGroup(false)}
        onOk={createNewGroup}
        count={selectList.length}
      />
      <ManageGroupModal
        visible={showManageGroup}
        onClose={() => setShowManageGroup(false)}
        onEdit={handleEditGroup}
        onDel={handelDelGroup}
        groupFresh={groupFresh}
      />
      <EditGroupModal
        item={editGroup}
        visible={editGroup !== null}
        onClose={() => setEditGroup(null)}
        onOk={saveEditGroup}
      />
    </div>
  );
};

interface Props extends RouteComponentProps {
  history: any;
  curUser: any;
}
interface OptionGrp {
  title: string
  filters: {
    lbl: string
    key: string
    opts: {
      name: string // option name
      value: string | number
    }[]
  }[]
}
const FilterModal: React.FC<ModalProps> = (props: ModalProps) => {
  const { visible, classes, onClose, options, canvasGroups, onChange, searchValue } = props;

  const [filter, setFilter] = useState<any>({});
  const [allSchools, setAllSchools] = useState<any[]>()
  const [optionGroups, setOptionGroups] = useState<OptionGrp[]>([]);
  useEffect(() => {
    if (visible) {
      setFilter({
        ...searchValue
      });
    }
    options && freshOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const freshOptions = () => {
    switch (searchValue.type) {
      case CanvasType.STUDENT:
        getStudentOptions().then(grps => setOptionGroups(grps))
        break
      case CanvasType.PROFESSIONAL:
        getProOptions().then(grps => setOptionGroups(grps))
        break
      case CanvasType.BUSINESS:
        getBizOptions().then(grps => setOptionGroups(grps))
        break
      case CanvasType.SCHOOL:
        getSchoolOptions().then(grps => setOptionGroups(grps))
        break
      default:
        break
    }
  }

  const getStudentOptions = async () => {
    let schools = allSchools
    if (!allSchools) {
      schools = (await getCustomized(true, ['school'])).school
      setAllSchools(schools)
    }
    const grps = [
      {
        title: 'Education filter',
        filters: [
          {
            lbl: 'PROGRAM',
            key: 'program',
            opts: getOptsFromOptionPool('program')
          },
          {
            lbl: 'COSMETOLOGY SCHOOL',
            key: 'school',
            opts: getOptsFromOptionPool('school', schools)
          },
          {
            lbl: 'GRADUATION DATE',
            key: 'graduationDate',
            opts: getOptsFromKeyCfgsDot(browse_canvas_filter_options.graduationDate)
          },
          {
            lbl: 'ATTENDANCE',
            key: 'attendance',
            opts: getOptsFromKeyCfgs({ options: attendanceOpts })
          },
          {
            lbl: 'GPA',
            key: 'gpa',
            opts: getOptsFromKeyCfgs({ options: gpaOpts })
          }
        ]
      },
      {
        title: 'Ocupation filter',
        filters: [
          {
            lbl: 'OPEN TO OPPORTUNITY',
            key: 'opportunityStatus',
            opts: getYesNoOpts('opportunityStatus')
          },
          {
            lbl: 'WORK PERMIT',
            key: 'workPermit',
            opts: getYesNoOpts('workPermit')
          },
          {
            lbl: 'WILLING TO RELOCATE',
            key: 'relocated',
            opts: getYesNoOpts('relocated')
          },
          {
            lbl: 'CURRENTLY HIRED',
            key: 'isCurrentWork',
            opts: getYesNoOpts('isCurrentWork')
          },
          {
            lbl: 'JOB CATEGORY',
            key: 'jobCategory',
            opts: getOptsFromOptionPool('category').map((i: any) => ({ name: i.name, value: i.value === blank_all ? i.value : i.name }))
          },
          {
            lbl: 'YEARS OF EXPERIENCE',
            key: 'yearOfExperience',
            opts: getOptsFromKeyCfgs(browse_canvas_filter_options.yearOfExperience)
          },
        ]
      },
      {
        title: 'KPI filter',
        filters: [
          {
            lbl: 'FREQUENCY OF VISIT',
            key: 'annualFrequencyOfVisit',
            opts: getOptsFromKeyCfgs(browse_canvas_filter_options.annualFrequencyOfVisit)
          },
          {
            lbl: 'REPEAT CLIENT RETENTION',
            key: 'repeatClientRetention',
            opts: getOptsFromKeyCfgs(browse_canvas_filter_options.repeatClientRetention)
          },
          {
            lbl: 'SERVICE TO RETAIL SALES',
            key: 'serviceToRetailSales',
            opts: getOptsFromKeyCfgs(browse_canvas_filter_options.serviceToRetailSales)
          },
          {
            lbl: 'AVERAGE TICKET',
            key: 'combinedAverageTicket',
            opts: getOptsFromKeyCfgs(browse_canvas_filter_options.combinedAverageTicket)
          }
        ]
      },
      {
        title: 'Additional info filter',
        filters: [
          {
            lbl: 'SOFTWARE',
            key: 'software',
            opts: getOptsFromOptionPool('software')
          },
          {
            lbl: 'PREFERRED BRAND',
            key: 'preferredBrand',
            opts: getOptsFromOptionPool('brand')
          },
          {
            lbl: 'SPECIALITY',
            key: 'speciality',
            opts: getOptsFromOptionPool('speciality')
          },
          {
            lbl: 'AWARD',
            key: 'award',
            opts: [
              emptyOpt,
              ...awardOpts.map((item: any) => {
                return {
                  name: item.name,
                  value: item.name
                }
              })
            ]
          },
        ]
      }
    ] as OptionGrp[]
    return grps
  }

  const getProOptions = async () => {
    const stuGrps = await getStudentOptions()
    const proGrps = [
      {
        title: 'Professional background filter',
        filters: [
          {
            lbl: 'LICENSE',
            key: 'license',
            opts: getYesNoOpts('license')
          },
          {
            lbl: 'STATE ISSUE',
            key: 'stateIssue',
            opts: getOptsFromOptionPool('state')
          }
        ]
      },
      ...stuGrps
    ] as OptionGrp[]
    return proGrps
  }

  const getSchoolOptions = async () => {
    return [
      {
        title: 'More filter',
        filters: [
          {
            lbl: 'SUPPORTED PROGRAM',
            key: 'supportedProgram',
            opts: getOptsFromOptionPool('program')
          },
          {
            lbl: 'ACCREDITED',
            key: 'isAccredited',
            opts: getYesNoOpts('isAccredited')
          },
          {
            lbl: 'DAY/NIGHT CLASS',
            key: 'classType',
            opts: classList
          },
          {
            lbl: 'RETAIL BRAND',
            key: 'retail',
            opts: getOptsFromOptionPool('brand')
          },
          {
            lbl: 'BACKBAR BRAND',
            key: 'backBar',
            opts: getOptsFromOptionPool('brand')
          },
          {
            lbl: 'SOFTWARE',
            key: 'software',
            opts: getOptsFromOptionPool('software')
          },
          {
            lbl: 'SPECIALITY',
            key: 'speciality',
            opts: getOptsFromOptionPool('speciality')
          },
          {
            lbl: 'BENEFITS',
            key: 'benefits',
            opts: getOptsFromOptionPool('companyBenefit')
          },
          {
            lbl: 'CANVAS GROUP',
            key: 'group',
            opts: canvasGroups
          },
        ]
      }
    ] as OptionGrp[]
  }

  const getBizOptions = async () => {
    return [
      {
        title: 'More filter',
        filters: [
          {
            lbl: 'CATEGORY',
            key: 'category',
            opts: getOptsFromOptionPool('category')
          },
          {
            lbl: 'RETAIL BRAND',
            key: 'retail',
            opts: getOptsFromOptionPool('brand')
          },
          {
            lbl: 'BACKBAR BRAND',
            key: 'backBar',
            opts: getOptsFromOptionPool('brand')
          },
          {
            lbl: 'SOFTWARE',
            key: 'software',
            opts: getOptsFromOptionPool('software')
          },
          {
            lbl: 'SPECIALITY',
            key: 'speciality',
            opts: getOptsFromOptionPool('speciality')
          },
          {
            lbl: 'BENEFITS',
            key: 'benefits',
            opts: getOptsFromOptionPool('companyBenefit')
          },
          {
            lbl: 'PAYMENT PREFFERENCE',
            key: 'paymentMode', 
            opts: getOptsFromOptionPool('paymentMode')
          },
          {
            lbl: 'CANVAS GROUP',
            key: 'group',
            opts: canvasGroups
          },
        ]
      }
    ] as OptionGrp[]
  }

  const getOptsFromOptionPool = (key: string, datas?: any[]) => {
    return [
      emptyOpt,
      ...(datas || options[key] || []).map((item: any) => ({
        name: item.name,
        value: item.id
      }))
    ]
  }

  const getOptsFromKeyCfgs = (cfg: { options: { name: string, key: string }[] }) => {
    return cfg.options.map(c => {
      let value = c.key === blank_all ? blank_all : getFilterNum(c.key)
      return {
        name: c.name,
        value: Array.isArray(value) ? value.join(',') : value
      }
    })
  }
  const getOptsFromKeyCfgsDot = (cfg: { options: { name: string, key: string }[] }) => {
    return cfg.options.map(c => {
      let value = c.key === blank_all ? blank_all : getFilterNumDot(c.key)
      return {
        name: c.name,
        value: Array.isArray(value) ? value.join(',') : value
      }
    })
  }
  const getYesNoOpts = (key: string) => {
    return [
      emptyOpt,
      {
        name: 'Yes',
        value: 'true'
      },
      {
        name: 'No',
        value: 'false'
      }
    ]
  }

  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      className={classes}
      centered={true}
      width={958}
    >
      <div className="modal-content">
        <Spin spinning={!optionGroups.length}>
        {
          optionGroups.map(grp => (
            <>
              <p className="sec-title">{grp.title}</p>
              <div className="filter-grp">
                {
                  grp.filters.map(f => (
                    <TextField
                      label={f.lbl}
                      variant="filled"
                      select
                      className="material-input input ml-24"
                      value={filter[f.key] || blank_all}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          [f.key]: e.target.value,
                        })
                      }
                    >
                      {f.opts.map((item, index) => (
                        <MenuItem key={`${f.key}-${index}`} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ))
                }
              </div>
            </>
          ))
        }
        </Spin>
        <Button
          className="btn-primary btn-ok"
          onClick={e => {
            onClose()
            onChange(filter)
          }}
        >Apply filter</Button>
      </div>
    </Modal>
  );
};
interface ModalProps {
  visible: boolean;
  options: any;
  canvasGroups: any;
  searchValue: any;
  classes?: string;
  onClose: any;
  onChange: any;
}

const CreateGroupModal: React.FC<{
  visible: boolean
  onClose: () => void
  classes?: string
  onOk: (name: string) => void
  count: number
}> = (props) => {
  const [name, setName] = useState('')

  useEffect(()=> {
    setName('')
  }, [props.visible])
  const {
    visible,
    onClose,
    onOk,
    classes,
    count
  } = props
  return <Modal
    footer={null}
    onCancel={() => onClose()}
    visible={visible}
    closable={true}
    className={`modal-group ${classes}`}
    centered={true}
    width={600}
    title='Creating a group'
  >
    <div className="modal-group-con">
      <div className="des">You are creating a group of {count} members. Please assign a name to this group and continue. You can always change the group name later in Reports/Manage group</div>
      <TextField
        label="GROUP NAME"
        variant="filled"
        className="material-input input"
        value={name}
        onChange={(e) =>
          setName(e.target.value)
        }
      ></TextField>
      <Button
        className="btn-primary"
        onClick={e => onOk(name)}
      >Create Group</Button>
    </div>
  </Modal>
}

const ManageGroupModal: React.FC<{
  visible: boolean
  onClose: () => void
  classes?: string
  onEdit: (item: any) => void
  onDel: (item: any) => void
  groupFresh: number
}> = (props) => {
  const [keyword, setKeyword] = useState('')
  const [groups, setGroups] = useState([] as any[])

  useEffect(() => {
    searchGroups()
    setKeyword('')
  }, [props.groupFresh])
  useEffect(()=> {
    setKeyword('')
  }, [props.visible])

  const searchGroups = () => {
    getGroupList(keyword).then(data => {
      setGroups(data.groups || [])
    })
  }

  const {
    visible,
    onClose,
    onEdit,
    onDel,
    classes,
  } = props
  return <Modal
    footer={null}
    onCancel={() => onClose()}
    visible={visible}
    closable={true}
    className={`modal-group ${classes}`}
    centered={true}
    width={600}
    title='Canvas Group'
  >
    <div className="modal-group-con">
      <Input
        placeholder={'search group by name'}
        prefix={<Icon type="search" />}
        value={keyword}
        onPressEnter={e => {
          searchGroups()
        }}
        onChange={e => setKeyword(e.target.value.trim())}
      />
      <List
        className="group-list"
        dataSource={groups}
        renderItem={(item: any) =>
          <List.Item className="group-opt-item" key={item.id}>
            <span className="avatar">{item.name?.charAt(0) || ''}</span>
            <span className="name">{item.name}</span>
            <Button type="link" className="opt-item-btn opt-edit-btn" onClick={e => onEdit(item)}>
              <Icon type='edit' theme="filled" />
            </Button>
            <Button type="link" className="opt-item-btn" onClick={e => onDel(item)}>
              <Icon type='delete' theme="filled" />
            </Button>
          </List.Item>
        }
      />
    </div>
  </Modal>
}

const EditGroupModal: React.FC<{
  visible: boolean
  onClose: () => void
  classes?: string
  item: any // editing item
  onOk: (name: string) => void
}> = (props) => {
  const [name, setName] = useState('')

  useEffect(() => {
    setName(props.item?.name)
  }, [props.item])

  const {
    visible,
    onClose,
    onOk,
    classes,
  } = props

  return <Modal
    footer={null}
    onCancel={() => onClose()}
    visible={visible}
    closable={true}
    className={`modal-group ${classes}`}
    centered={true}
    width={600}
    title='Group name'
  >
    <div className="modal-group-con">
      <TextField
        label="GROUP NAME"
        variant="filled"
        className="material-input input"
        value={name}
        onChange={(e) =>
          setName(e.target.value)
        }
      ></TextField>
      <Button
        className="btn-primary"
        onClick={e => onOk(name)}
      >Save</Button>
    </div>
  </Modal>
}
export default mapProp(modle_user)(Canvas);
