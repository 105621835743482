import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { User } from "../models/model_user";
import Close from "../assets/images/ic_close.svg";
import Logo from "../assets/images/new_logo_white.svg";
import IconFb from "../assets/images/ic_fb_white.png";
import IconIns from "../assets/images/ic_ins_white.svg";
import "./component_home_menu_modal.less";
import { module_chat } from "../models/model_chat";
import { router_homepage_about, router_homepage_index } from "../utils/enum";
import { partnersUrl, wordPressBlogUrl } from "../utils/constants";
import { Link } from "react-router-dom";
import { facebookUrl, instagramUrl } from "../utils/constants";
import {
  ModelNotifications,
  module_notifications,
} from "./../models/model_notifications";
import { HashLink } from "react-router-hash-link";
import { mapProp } from "module-reaction";
import { router_get_started } from "../utils/enum";
import IconArrow from "../assets/images/ic_arrow_down.svg";
import { getUtoken } from "../api/Auth";

interface Props extends ModelNotifications {
  className?: string;
  visible?: boolean;
  history?: any;
  onLogout?: Function;
  isLogin?: boolean;
  onLogin?: Function;
  onClose: Function;
  user?: User;
  unreadCount?: number;
  onPostOpportunityClick?: Function;
}

const HomeMenuModal: React.FC<Props> = (props) => {
  const {
    className,
    visible,
    onClose,
    isLogin = false,
    history,
    user,
    onLogout,
    onLogin,
    notificationCount,
    unreadCount = 0,
    onPostOpportunityClick,
  } = props;
  const [isOppActive, setIsOppActive] = useState(false);
  const [isPortActive, setIsPortActive] = useState(false);
  const [isResActive, setIsResActive] = useState(false);
  const [isProfActive, setIsProfActive] = useState(false);
  useEffect(() => {
    if (isOppActive) {
      setIsPortActive(false);
      setIsResActive(false);
      setIsProfActive(false);
    }
  }, [isOppActive]);
  useEffect(() => {
    if (isPortActive) {
      setIsOppActive(false);
      setIsResActive(false);
      setIsProfActive(false);
    }
  }, [isPortActive]);
  useEffect(() => {
    if (isResActive) {
      setIsOppActive(false);
      setIsPortActive(false);
      setIsProfActive(false);
    }
  }, [isResActive]);
  useEffect(() => {
    if (isProfActive) {
      setIsOppActive(false);
      setIsPortActive(false);
      setIsResActive(false);
    }
  }, [isProfActive]);
  function checkLoginThenGo(location: string) {
    return history.push(isLogin ? location : router_get_started);
  }
  return (
    <Modal
      footer={null}
      onCancel={() => onClose()}
      visible={visible}
      closable={false}
      className={className}
      centered={true}
      wrapClassName="menu-modal-style"
    >
      <div className="menu-modal">
        <div className="title-sec">
          <a href="https://beautista.com">
            <img
              alt=""
            src={Logo}
            className="logo"
            // onClick={() => history.push(router_homepage_index)}
          />
          </a>
          <img alt="" src={Close} className="close" onClick={() => onClose()} />
        </div>
        <div className="top-sec">
          <button
            className="accordian-btn"
            id="opportunities"
            onClick={() => setIsOppActive(!isOppActive)}
          >
            Opportunities
            <img
              src={IconArrow}
              alt=""
              className={isOppActive ? "arrow" : "arrow rotate"}
            />
          </button>
          {isOppActive && (
            <div className="panel">
              <span
                onClick={() => {
                  checkLoginThenGo("/homepage-opportunity");
                  onClose();
                }}
              >
                View Opportunities
              </span>
              <span
                onClick={() => {
                  onPostOpportunityClick();
                  onClose();
                }}
              >
                Create Opportunity
              </span>
            </div>
          )}
          <button
            className="accordian-btn"
            id="portfolios"
            onClick={() => setIsPortActive(!isPortActive)}
          >
            Portfolios
            <img
              src={IconArrow}
              alt=""
              className={isPortActive ? "arrow" : "arrow rotate"}
            />
          </button>
          {isPortActive && (
            <div className="panel">
              {!isLogin && (
                <span
                  onClick={() => {
                    checkLoginThenGo("/add-canvas");
                    onClose();
                  }}
                >
                  Create a Canvas
                </span>
              )}
              <span
                onClick={() => {
                  checkLoginThenGo("/homepage-canvas/professional");
                  onClose();
                }}
              >
                Professional Canvas
              </span>
              <span
                onClick={() => {
                  checkLoginThenGo("/homepage-canvas/business");
                  onClose();
                }}
              >
                Business Canvas
              </span>
              <span
                onClick={() => {
                  checkLoginThenGo("/homepage-canvas/school");
                  onClose();
                }}
              >
                School Canvas
              </span>
            </div>
          )}
          <button
            className="accordian-btn"
            id="resources"
            onClick={() => setIsResActive(!isResActive)}
          >
            Resources
            <img
              src={IconArrow}
              alt=""
              className={isResActive ? "arrow" : "arrow rotate"}
            />
          </button>
          {isResActive && (
            <div className="panel">
              <span
                onClick={() => {
                  window.location.href = "https://sites.canvasme.com/video/";
                }}
              >
                How it Works
              </span>
              <HashLink smooth to="/#faq">
                <span>FAQs</span>
              </HashLink>
              <span
                onClick={() => {
                  window.location.href = wordPressBlogUrl;
                }}
              >
                Blog
              </span>
              <span
                onClick={() => {
                  window.location.href = partnersUrl;
                }}
              >
                Partners
              </span>
            </div>
          )}
        </div>
        <div className="divider" />
        {!isLogin ? (
          <div className="bottom-sec">
            <span onClick={() => onLogin && onLogin()}>Sign in</span>
            <br />
            <Button
              className="btn-primary-light btn-start-free"
              onClick={() => history.push("/get-started")}
            >
              SIGN UP
            </Button>
          </div>
        ) : (
          <div className="bottom-sec">
            <button
              className="accordian-btn"
              onClick={() => setIsProfActive(!isProfActive)}
            >
              <img
                className="profile-img"
                alt=""
                src={user && user.profile && user.profile.avatar}
                hidden={!user || !user.profile || !user.profile.avatar}
              />
              {user &&
                user.profile &&
                `${user?.profile?.firstName} ${user?.profile?.lastName}`}
              <img
                src={IconArrow}
                alt=""
                className={isProfActive ? "arrow" : "arrow rotate"}
              />
            </button>
            {isProfActive && (
              <>
                {user?.profile?.target === "admin" ? (
                  <>
                    <span onClick={() => checkLoginThenGo("/admin-user")}>
                      My Users
                    </span>
                    <span
                      onClick={() => checkLoginThenGo("/admin-opportunity")}
                    >
                      Opportunity Dashboard
                    </span>
                    <span onClick={() => history.push("/admin-canvas")}>
                      Canvas Dashboard
                    </span>
                    <span onClick={() => checkLoginThenGo("/admin-school")}>
                      School Dashboard
                    </span>
                    <span onClick={() => checkLoginThenGo("/admin-data")}>
                      KPI Dashboard
                    </span>
                    <span onClick={() => checkLoginThenGo("/admin-external")}>
                     External Payment
                    </span>
                    <span
                      className={unreadCount > 0 ? "notification-active" : ""}
                      onClick={() => checkLoginThenGo("/message")}
                    >
                      Messages
                    </span>
                    <span
                      className={
                        notificationCount > 0 ? "notification-active" : ""
                      }
                      onClick={() => checkLoginThenGo("/notifications")}
                    >
                      Notifications
                    </span>
                    <span onClick={() => checkLoginThenGo("/admin-featured")}>
                      Featured
                    </span>
                  </>
                ) : (
                  <>
                    <span onClick={() => checkLoginThenGo("/my-dashboard")}>
                      Dashboard
                    </span>
                    <span onClick={() => checkLoginThenGo("/my-canvases")}>
                      My Portfolio
                    </span>
                    <span onClick={() => checkLoginThenGo("/bookmarks")}>
                      Bookmarks
                    </span>
                    <span
                      className={unreadCount > 0 ? "notification-active" : ""}
                      onClick={() => checkLoginThenGo("/message")}
                    >
                      Messages
                    </span>
                    <span
                      className={
                        notificationCount > 0 ? "notification-active" : ""
                      }
                      onClick={() => checkLoginThenGo("/notifications")}
                    >
                      Notifications
                    </span>
                    <span onClick={() => checkLoginThenGo("/membership")}>
                      Membership
                    </span>
                    <span onClick={() => checkLoginThenGo("/settings")}>
                      Account Settings
                    </span>
                    <span onClick={async () => {
                      const tab = window.open();
                      try {
                        const { utoken } = await getUtoken();
                        if (utoken) {
                           tab.location.href = `https://u.canvasme.com/?utoken=${utoken}`
                        }else{
                          tab.location.href = `https://u.canvasme.com/`
                        }
                      } catch {}
                    }}>
                      BEAUTISTA Connect
                    </span>
                  </>
                )}
              </>
            )}
            <span className="sign-out" onClick={() => onLogout && onLogout()}>
              Sign Out
            </span>
          </div>
        )}
        <div className="divider" />
        <div className="footer-sec">
          <div className="left-wrap">
            <Link to={router_homepage_about}>About</Link>
            <span>Contact</span>
          </div>
          <div className="right-wrap">
            <a href={facebookUrl}>
              <img alt="" src={IconFb} />
            </a>
            <a href={instagramUrl}>
              <img alt="" src={IconIns} />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default mapProp(
  module_notifications,
  "notificationCount"
)(mapProp(module_chat)(HomeMenuModal));
