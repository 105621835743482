import { ModuleStore, ModuleAction, KV, plusAction } from "module-reaction";
import _ from "lodash";
import { getCustomized } from "../api/Options";
import {
  getCanvas,
  editCanvas,
  createCanvas,
  updateCanvasStatus,
} from "../api/Canvas";
import { getUserInfo } from "../api/Auth";
import { Storage } from "aws-amplify";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { IMediaItem } from "../components/component_media_item";
import {
  MediaType,
  CanvasType,
  UserRole,
  router_insta_auth,
} from "../utils/enum";
import { IAward } from "../components/component_award_item";
import { ISocialMedia } from "../components/component_social_media_item";
import { AWS_URL } from "../utils/constants";
import { getMediaInfo } from "../utils/mediaHelper";
import { IEmployment } from "../components/component_employment_item";
import moment from "moment";
import { User } from "./model_user";
import { delInstInfo } from "../api/Insta";
import { global_router } from "../routers";
import { htmlDecode, isHtmlTag } from "../utils/utils";
import store from '../utils/store';
export const MODULE_STUDENT_CANVAS = "module_student_canvas";

const tempSocial = [
  {
    type: 0,
    label: "instagram",
  },
  {
    type: 1,
    label: "youtube",
  },
  {
    type: 1,
    label: "facebook",
  },
  {
    type: 0,
    label: "tiktok",
  },
];

export const educationItem = {
  uuid: "",
  program: {},
  school: {},
  graduationDate: null,
  gpa: "",
  attendance: "",
  workPermit: "",
  hasWorkPermit: "no",
};

export interface ModuleStudentCanvas extends ModuleStore {
  id: number;
  type: string;
  slug: string;
  owner: number;
  user: User;
  //options
  relocateOptions: any[];
  preferredBrandsOptions: any[];
  specialityOptions: any[];
  softwareOptions: any[];
  paymentOptions: any[];
  programOptions: any[];
  categoryList: any[];
  stateIssueList: any[];
  //pagedata
  pronouns: string;
  title: string;
  name: string;
  experience: number;
  location: any;
  showDesc: boolean;
  willingToRelocate: boolean;
  opportunityStatus: boolean;
  showOpportunityStatus: boolean;
  relocateList: any[];
  aboutMe: string;
  why: string;
  logo: IMediaItem;
  featuredPhoto: IMediaItem;
  portraitPhoto: IMediaItem;
  featuredVideo: IMediaItem;
  portfolioPhotos: IMediaItem[];
  extraVideo: IMediaItem[];
  awardList: IAward[];
  preferredBrands: any[];
  specialities: any[];
  softwares: any[];
  kpi: any;
  employment: IEmployment[];
  payment: any;
  instagramHandle: string;
  instagramPhotos: any[];
  socialMediaList: ISocialMedia[];
  educations: any[];
  categories: any[];
  emptyFields: string[];
  //origin
  originEdus: any[];
  bookingUrl: string;
}

export const module_student_canvas: ModuleStudentCanvas = {
  module: MODULE_STUDENT_CANVAS,
  id: 0,
  type: "student",
  slug: "",
  owner: 0,
  user: undefined as any,
  //options
  relocateOptions: [],
  preferredBrandsOptions: [],
  specialityOptions: [],
  softwareOptions: [],
  paymentOptions: [],
  programOptions: [],
  categoryList: [],
  stateIssueList: [],
  //pagedata
  pronouns: "",
  title: "",
  name: "",
  experience: -1,
  location: {
    uuid: uuidv4(),
  },
  showDesc: false,
  willingToRelocate: false,
  bookingUrl: null,
  opportunityStatus: false,
  showOpportunityStatus: false,
  relocateList: [],
  aboutMe: "",
  why: "",
  logo: {
    image: "",
    title: "Logo",
    dimensions: "100x60",
    fileSize: 1,
    url: "",
    type: MediaType.LOGO,
  },
  featuredPhoto: {
    image: "",
    title: "Featured Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.FEATURED_PHOTO,
  },
  portraitPhoto: {
    image: "",
    title: "Bio Photo",
    dimensions: "1440x758",
    fileSize: 5,
    url: "",
    type: MediaType.PORTRAIT_PHOTO,
  },
  featuredVideo: {
    image: "",
    title: "Featured Video",
    dimensions: "1240x800",
    fileSize: 10,
    url: "",
    type: MediaType.FEATURED_VIDEO,
  },
  portfolioPhotos: [],
  extraVideo: [],
  awardList: [],
  preferredBrands: [],
  specialities: [],
  softwares: [],
  kpi: {},
  employment: [] as any,
  payment: {},
  instagramHandle: '',
  instagramPhotos: [],
  socialMediaList: tempSocial,
  educations: [{ ...educationItem }] as any[],
  categories: [] as any[],
  emptyFields: [] as string[],
  originEdus: [] as any[],
};

export const GetUserInfoAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (type: any, module: ModuleStudentCanvas) => {
    const auth = await getUserInfo();
    if (auth) {
      const firstName =
        (auth &&
          auth.user &&
          auth.user.profile &&
          auth.user.profile.firstName) ||
        "";
      const lastName =
        (auth &&
          auth.user &&
          auth.user.profile &&
          auth.user.profile.lastName) ||
        "";
      let pageData = {
        type: type,
        name: `${firstName} ${lastName}`,
      } as any;
      if (
        auth.user &&
        auth.user.categories &&
        auth.user.profile &&
        UserRole.BEAUTY_PRO === auth.user.profile.career
      ) {
        const cates = _.map(auth.user.categories, (item) => {
          if (type === CanvasType.STUDENT) {
            return {
              id: item.id,
              name: item.name,
            };
          } else {
            let newItem = {
              id: item.id,
              name: item.name,
              licenseNumber: item.licenseNumber,
              otherName: "",
            } as any;
            if (!_.isEmpty(module.categoryList)) {
              const curCate = _.find(module.categoryList, {
                id: item.id,
              });
              if (!curCate) {
                newItem = {
                  id: 0,
                  name: "Other",
                  licenseNumber: item.licenseNumber,
                  otherName: item.name,
                };
              }
            }
            return newItem;
          }
        });
        pageData = { ...pageData, categories: cates };
      }
      if (auth.user && auth.user.profile && auth.user.profile.program) {
        const educations = [
          {
            ...module.educations[0],
            program: auth.user.profile.program,
          },
        ];
        if (auth.user.profile.program2) {
          educations.push({
            ...educationItem,
            program: auth.user.profile.program2,
          });
        }
        pageData = { ...pageData, educations, user: auth.user };
      }

      pageData = { ...pageData, showDesc: true };

      return pageData;
    }
    return {};
  },
};

export const GetPageDataAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (slug: string, module: ModuleStudentCanvas) => {
    if (!slug) {
      return {};
    }
    const res = await getCanvas(slug);
    const auth = await getUserInfo();
    if (res) {
      let social = [...tempSocial];
      social = _.map(social, (socialItem) => {
        if (_.get(res.socialMedia, socialItem.label)) {
          return {
            ...socialItem,
            value: _.get(res.socialMedia, socialItem.label),
          };
        } else {
          return socialItem;
        }
      });
      const firstName =
        (auth &&
          auth.user &&
          auth.user.profile &&
          auth.user.profile.firstName) ||
        "";
      const lastName =
        (auth &&
          auth.user &&
          auth.user.profile &&
          auth.user.profile.lastName) ||
        "";

      const loca = res.detail.locations[0];
      let cates = [];
      if (res.type === CanvasType.STUDENT) {
        cates = _.map(res.detail.categories, (item) => {
          return {
            id: item.optionId,
            name: item.name,
          };
        });
      } else {
        cates = _.map(res.detail.categories, (item) => {
          let newItem = {
            id: item.optionId,
            name: item.name,
            licenseNumber: item.licenseNumber,
            stateId: (item.state && item.state.id) || 0,
            stateName: (item.state && item.state.name) || "",
            otherName: "",
          } as any;
          if (!_.isEmpty(module.categoryList)) {
            const curCate = _.find(module.categoryList, {
              id: item.optionId,
            });
            if (!curCate) {
              newItem = {
                id: 0,
                name: "Other",
                licenseNumber: item.licenseNumber,
                stateId: (item.state && item.state.id) || 0,
                stateName: (item.state && item.state.name) || "",
                otherName: item.name,
              };
            }
          }
          return newItem;
        });
      }
      const pageData = {
        id: res.id,
        slug: res.slug,
        owner: res.owner || 0,
        type: res.type || "student",
        title: res.introduction.title || "",
        name: res.introduction.name,
        experience: res.introduction.yearOfExperience || -1,
        status: res.status,
        location: loca
          ? {
            uuid: loca.uuid,
            location: loca.address,
            city: loca.city,
            coordinates: loca.coordinates,
            country: loca.country,
            countryShort: loca.countryShort,
            state: loca.state,
            stateShort: loca.stateShort,
          }
          : {
            uuid: uuidv4(),
          },
        willingToRelocate: res.introduction.isRelocated || false,
        bookingUrl: res.bookingUrl,
        pronouns: res.pronouns,
        opportunityStatus: res.introduction.opportunityStatus || false,
        showOpportunityStatus: res.introduction.showOpportunityStatus || false,
        relocateList: res.introduction.relocateTo || [],
        aboutMe: isHtmlTag(res.introduction.description)? res.introduction.description : htmlDecode(res.introduction.description),
        why: isHtmlTag(res.introduction.reason)? res.introduction.reason : htmlDecode(res.introduction.reason),
        showDesc: true,
        logo: (res.media && res.media.logo) || {
          image: "",
          title: "Logo",
          dimensions: "100x60",
          fileSize: 1,
          url: "",
          type: MediaType.LOGO,
        },
        featuredPhoto: (res.media && res.media.featuredPhoto) || {
          image: "",
          title: "Featured Photo",
          dimensions: "1440x758",
          fileSize: 5,
          url: "",
          type: MediaType.FEATURED_PHOTO,
        },
        portraitPhoto: (res.media && res.media.portraitPhoto) || {
          image: "",
          title: "Bio Photo",
          dimensions: "1440x758",
          fileSize: 5,
          url: "",
          type: MediaType.PORTRAIT_PHOTO,
        },
        featuredVideo: (res.media && res.media.featuredVideo) || {
          image: "",
          title: "Featured Video",
          dimensions: "1240x800",
          fileSize: 10,
          url: "",
          type: MediaType.FEATURED_VIDEO,
        },
        portfolioPhotos: (res.media && res.media.portfolioPhoto) || [],
        extraVideo: (res.media && res.media.additionalVideo) || [],
        awardList: res.achievements || [],
        preferredBrands:
          _.map(res.additionalInformation.preferredBrands, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [],
        specialities:
          _.map(res.additionalInformation.specialities, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [],
        softwares:
          _.map(res.additionalInformation.softwares, (item) => {
            return {
              id: item.optionId,
              name: item.name,
            };
          }) || [],
        kpi: res.additionalInformation.KPI || {},
        employment:
          _.map(res.employments, (item) => {
            let position = [] as any[];
            if (item.position) {
              position = _.map(item.position.split(","), (item) => {
                return {
                  name: item,
                };
              });
            }
            let jobCategory = [] as any[];
            if (item.jobCategory) {
              jobCategory = _.map(item.jobCategory.split(","), (item) => {
                return {
                  name: item,
                };
              });
            }
            return {
              uuid: item.uuid,
              title: item.title || "",
              position: position,
              jobCategory: jobCategory,
              isCurrentWork: item.isCurrentWork,
              company: item.company,
              startDate: item.startDate,
              endDate: item.endDate,
              description: item.description || "",
            };
          }) || [],
        payment: res.additionalInformation.paymentMode || {},
        socialMediaList: social,
        educations: !_.isEmpty(res.educations)
          ? _.map(res.educations, (item) => {
            return {
              ...item,
              schoolId: item.schoolId || item.schoolOptionId,
              program: item.program?.optionId
                ? {
                  id: item.program.optionId,
                  name: item.program.name,
                }
                : {},
            };
          })
          : [{ ...educationItem }],
        originEdus: res.educations,
        categories: cates,
      } as any;
      return pageData;
    }
    return {};
  },
};

export const GetSelectionsAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async () => {
    const res = await getCustomized(true, [
      "category",
      "state",
      "brand",
      "software",
      "speciality",
      "paymentMode",
      "state",
      "program",
    ]);

    if (res) {
      const params = {
        preferredBrandsOptions: res.brand,
        paymentOptions: res.paymentMode,
        softwareOptions: res.software,
        specialityOptions: res.speciality,
        relocateOptions: res.state,
        programOptions: res.program,
        categoryList: res.category.professional.concat({
          id: 0,
          name: "Other",
        }),
        stateIssueList: res.state,
      };
      return params;
    }

    return {};
  },
};

export const UpdatePageInfoAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (options: KV, module: ModuleStudentCanvas) => {
    return { ...options };
  },
};

export const EditAwardAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (award: IAward, module: ModuleStudentCanvas) => {
    const oldList = module.awardList;
    if (!award.uuid) {
      return { awardList: [...oldList, { ...award, uuid: uuidv4() }] };
    } else {
      const editedItem = _.find(oldList, { uuid: award.uuid });
      if (editedItem) {
        editedItem.issuer = award.issuer;
        editedItem.issueYear = award.issueYear;
        editedItem.title = award.title;
        return { awardList: [...oldList] };
      } else {
        return {};
      }
    }
  },
};

export const EditEmploymentAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (employment: IEmployment, module: ModuleStudentCanvas) => {
    const oldList = module.employment;
    if (!employment.uuid) {
      const newEmp = [...oldList, { ...employment, uuid: uuidv4() }];
      return { employment: newEmp };
    } else {
      const editedItem = _.find(oldList, { uuid: employment.uuid });
      if (editedItem) {
        editedItem.title = employment.title;
        editedItem.jobCategory = employment.jobCategory;
        editedItem.isCurrentWork = employment.isCurrentWork;
        editedItem.description = employment.description;
        editedItem.company = employment.company;
        editedItem.position = employment.position;
        editedItem.startDate = employment.startDate;
        editedItem.endDate = employment.endDate;
        return { employment: [...oldList] };
      } else {
        return {};
      }
    }
  },
};

export const DeleteEmploymentAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (employment: IEmployment, module: ModuleStudentCanvas) => {
    const oldList = module.employment;
    const newList = _.filter(oldList, (item) => {
      return item.uuid !== employment.uuid;
    });
    return { employment: newList };
  },
};

export const DeleteAwardAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (award: IAward, module: ModuleStudentCanvas) => {
    const oldList = module.awardList;
    const newList = _.filter(oldList, (item) => {
      return item.uuid !== award.uuid;
    });
    return { awardList: newList };
  },
};

export const SocialMediaInputAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (media: any, module: ModuleStudentCanvas) => {
    const oldList = module.socialMediaList;
    const item = _.find(oldList, { label: media.label });
    if (item) {
      item.value = media.value;
      return { socialMediaList: [...oldList] };
    }
    return {};
  },
};

export const ReorderPortfolioImages: ModuleAction<KV, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (params: KV, module: ModuleStudentCanvas) => {
    const { reorderedItems } = params;
    return { portfolioPhotos: reorderedItems };
  }
}

export const ReorderPortfolioVidoes: ModuleAction<KV, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (params: KV, module: ModuleStudentCanvas) => {
    const { reorderedItems } = params;
    return { extraVideo: reorderedItems };
  }
}

export const UploadFileAction: ModuleAction<KV, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (params: KV, module: ModuleStudentCanvas) => {
    const { mediaInfo, type } = params;
    if (mediaInfo.error) return {};
    //
    const remotePath = `${module.type === CanvasType.STUDENT
        ? "student_canvas"
        : "professional_canvas"
      }/${type}/`;
    const customPrefix = {
      public: remotePath,
    };
    let imageKey = "";
    let videoKey = "";
    let hasError = false;
    if (mediaInfo.image) {
      const result: any = await Storage.put(
        uuidv4() + ".png",
        mediaInfo.image,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        imageKey = result.key;
      }
    }
    if (mediaInfo.video) {
      const sf = _.last(mediaInfo.video.name.split(".")) || ".MP4";
      const result: any = await Storage.put(
        uuidv4() + `.${sf}`,
        mediaInfo.video,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        videoKey = result.key;
      }
    }
    if (hasError) {
      return {};
    }
    const imgUrl = imageKey ? AWS_URL + remotePath + imageKey : '';
    const videoUrl = videoKey ? AWS_URL + remotePath + videoKey : "";
    switch (type) {
      case MediaType.LOGO:
        return {
          logo: {
            ...module.logo,
            image: imgUrl,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            keepSize: mediaInfo.keepSize,
            reposition: mediaInfo.reposition,
          },
        };
      case MediaType.FEATURED_PHOTO:
        return {
          featuredPhoto: {
            ...module.featuredPhoto,
            image: imgUrl,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            keepSize: mediaInfo.keepSize,
            reposition: mediaInfo.reposition,
          },
        };
      case MediaType.PORTRAIT_PHOTO:
        return {
          portraitPhoto: {
            ...module.portraitPhoto,
            image: imgUrl,
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            keepSize: mediaInfo.keepSize,
            reposition: mediaInfo.reposition,
          },
        };
      case MediaType.FEATURED_VIDEO:
        if (videoUrl) {
          return {
            featuredVideo: {
              ...module.featuredVideo,
              image: imgUrl,
              video: videoUrl,
              dimensions: mediaInfo.dimensions,
              fileSize: mediaInfo.fileSize,
            },
          };
        } else {
          return {};
        }

      case MediaType.PORTFOLIO_PHOTO:
        if (imgUrl) {
          const oldAdds = module.portfolioPhotos;
          const item = {
            image: imgUrl,
            video: videoUrl,
            title: "Portfolio Photo",
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            url: "",
            type: MediaType.PORTFOLIO_PHOTO,
            keepSize: mediaInfo.keepSize,
            reposition: mediaInfo.reposition,
          };
          return {
            portfolioPhotos: [...oldAdds, item],
          };
        } else {
          return {};
        }
      case MediaType.ADDITIONAL:
        if (videoUrl) {
          const oldAdds = module.extraVideo;
          const item = {
            image: imgUrl,
            video: videoUrl,
            title: "Portfolio Video",
            dimensions: mediaInfo.dimensions,
            fileSize: mediaInfo.fileSize,
            url: "",
            type: MediaType.FEATURED_VIDEO,
          };
          return {
            extraVideo: [...oldAdds, item],
          };
        }
    }
    return {};
  },
  maxProcessSeconds: 300,
};

export const EditPortfolioPhotoAction: ModuleAction<KV, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (params: KV, module: ModuleStudentCanvas) => {
    const { mediaInfo, index } = params;
    // let maxSize = 10;
    if (mediaInfo.error) return {};
    //
    const remotePath = `${module.type === CanvasType.STUDENT
        ? "student_canvas"
        : "professional_canvas"
      }/${MediaType.PORTFOLIO_PHOTO}/`;
    const customPrefix = {
      public: remotePath,
    };
    let imageKey = "";
    let hasError = false;
    if (mediaInfo.image) {
      const result: any = await Storage.put(
        uuidv4() + ".png",
        mediaInfo.image,
        {
          customPrefix,
          progressCallback(progress: any) {
            const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            store.dispatch({
              type: 'SET_UPLOAD_PROGRESS',
              payload: uploadProgress
            });
          }
        }
      ).catch((error) => {
        message.error(error.message);
        hasError = true;
      });
      if (result && result.key) {
        imageKey = result.key;
      }
    }
    if (hasError) {
      return {};
    }
    const imgUrl = imageKey ? AWS_URL + remotePath + imageKey : '';
    if (imgUrl) {
      const oldAdds = [...module.portfolioPhotos];
      const cur = oldAdds[index];
      if (!cur) {
        return {};
      }
      cur.image = imgUrl;
      cur.dimensions = mediaInfo.dimensions;
      cur.fileSize = mediaInfo.fileSize;
      cur.keepSize = mediaInfo.keepSize;
      cur.reposition = mediaInfo.reposition;
      return {
        portfolioPhotos: oldAdds,
      };
    } else {
      return {};
    }
  },
  maxProcessSeconds: 300,
};

export const DeleteMediaAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (params: KV, module: ModuleStudentCanvas) => {
    const { type, index } = params;
    switch (type) {
      case MediaType.LOGO:
        return {
          logo: {
            image: "",
            title: "Logo",
            dimensions: "100x60",
            fileSize: 1,
            url: "",
            type: MediaType.LOGO,
          },
        };
      case MediaType.FEATURED_PHOTO:
        return {
          featuredPhoto: {
            image: "",
            title: "Featured Photo",
            dimensions: "1440x758",
            fileSize: 5,
            url: "",
            type: MediaType.FEATURED_PHOTO,
          },
        };
      case MediaType.PORTRAIT_PHOTO:
        return {
          portraitPhoto: {
            image: "",
            title: "Bio Photo",
            dimensions: "1440x758",
            fileSize: 5,
            url: "",
            type: MediaType.PORTRAIT_PHOTO,
          },
        };
      case MediaType.FEATURED_VIDEO:
        return {
          featuredVideo: {
            image: "",
            title: "Featured Video",
            dimensions: "1240x800",
            fileSize: 10,
            url: "",
            type: MediaType.FEATURED_VIDEO,
          },
        };
      case MediaType.PORTFOLIO_PHOTO:
        const newAdd = _.filter(module.portfolioPhotos, (item, idx) => {
          return index !== idx;
        });
        return { portfolioPhotos: newAdd };
      case MediaType.ADDITIONAL:
        const newList = _.filter(module.extraVideo, (item, idx) => {
          return index !== idx;
        });
        return { extraVideo: newList };
    }
    return {};
  },
};

export const SubmitToPreviewAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (history: any, module: ModuleStudentCanvas) => {
    const emptyFields = [] as string[];
    _.forEach(module.educations, (item) => {
      if (!item.program || !item.program.name) {
        emptyFields.push("Program");
      }
      if (!item.schoolName) {
        emptyFields.push("School");
      }
    });
    if (!_.isEmpty(emptyFields)) {
      return { emptyFields: emptyFields };
    }
    let res;
    const request = convertModalToRequest(module);
    if (!module.id) {
      res = await createCanvas(
        module.type === CanvasType.PROFESSIONAL
          ? CanvasType.PROFESSIONAL
          : CanvasType.STUDENT,
        request
      );
    } else {
      res = await editCanvas(module.id, request);
    }
    if (res && (res.slug || res.id)) {
      history.push(
        `/${module.type === CanvasType.STUDENT
          ? "student-canvas"
          : "professional-canvas"
        }/preview/${res.slug || res.id}`
      );
    }
    return {};
  },
};

export const SaveAsDraftAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (history: any, module: ModuleStudentCanvas) => {
    const emptyFields = [] as string[];
    _.forEach(module.educations, (item) => {
      if (!item.program || !item.program.name) {
        emptyFields.push("Program");
      }
      if (!item.schoolName) {
        emptyFields.push("School");
      }
    });
    let res;
    const request = convertModalToRequest(module);
    if (!module.id) {
      res = await createCanvas(
        module.type === CanvasType.PROFESSIONAL
          ? CanvasType.PROFESSIONAL
          : CanvasType.STUDENT,
        request
      );
    } else {
      res = await editCanvas(module.id, request);
    }
    if (res) {
      if (!_.isEmpty(emptyFields)) {
        await updateCanvasStatus(res.id, "private");
      }
      history.push("/my-canvases");
    }
    return {};
  },
};

export const SubmitToPublishAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (history: any, module: ModuleStudentCanvas) => {
    const emptyFields = [] as string[];
    if (module.type === CanvasType.PROFESSIONAL) {
      if (!module.location.location) {
        emptyFields.push("Location");
      }
      // if (_.isEmpty(module.categories)) {
      //   emptyFields.push("Category");
      // }
    }
    if (module.type === CanvasType.STUDENT) {
      _.forEach(module.educations, (item) => {
        if (!item.program || !item.program.name) {
          emptyFields.push("Program");
        }
        if (!item.schoolName) {
          emptyFields.push("School");
        }
      });
    }
    if (!_.isEmpty(emptyFields)) {
      return { emptyFields: emptyFields };
    }
    let res;
    const request = convertModalToRequest(module);
    if (!module.id) {
      res = await createCanvas(
        module.type === CanvasType.PROFESSIONAL
          ? CanvasType.PROFESSIONAL
          : CanvasType.STUDENT,
        request
      );
    } else {
      res = await editCanvas(module.id, request);
    }
    if (res && res.id) {
      const publish = await updateCanvasStatus(res.id);
      if (publish && publish.success) {
        history.push(
          `/publish-success/${module.type === CanvasType.STUDENT ? "student" : "professional"
          }?id=${res.slug}`
        );
      }
    }
    return {};
  },
};

export const AutoSaveAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (history: any, module: ModuleStudentCanvas) => {
    const request = convertModalToRequest(module);
    if (!module.id) {
      const res = await createCanvas(
        module.type === CanvasType.PROFESSIONAL
          ? CanvasType.PROFESSIONAL
          : CanvasType.STUDENT,
        request
      );
      if (res && res.slug) {
        const from = `${window.location.pathname}/${res.slug}`;
        history && history.push({
          pathname: router_insta_auth,
          query: { from: from },
        });
      }
      return { id: res.id };
    } else {
      const res = await editCanvas(module.id, request);
      if (res) {
        history && history.push({
          pathname: router_insta_auth,
          query: { from: window.location.pathname },
        });
      }
    }
    return {};
  },
};

export const DisconnectInstaAction: ModuleAction<any, ModuleStudentCanvas> = {
  module: MODULE_STUDENT_CANVAS,
  process: async (payload: any, module: ModuleStudentCanvas) => {
    const res = await delInstInfo(module.id)
    if (res?.success) {
      const { socialMediaList } = module
      const instaMedia = _.find(socialMediaList, { label: "instagram" })
      delete instaMedia!.value

      plusAction(AutoSaveAction, null) // just save , not go to insta-auth
      return { socialMediaList }
    }
    return {}
  }
}
const convertModalToRequest = (modal: ModuleStudentCanvas) => {
  let requestLocation = [];
  if (modal.location && modal.location.location) {
    requestLocation = _.map([modal.location], (item) => {
      let location = {
        uuid: item.uuid,
        address: item.location,
      } as any;
      if (!_.isEmpty(item.country)) {
        location = {
          ...location,
          country: item.country,
        };
      }
      if (!_.isEmpty(item.countryShort)) {
        location = {
          ...location,
          countryShort: item.countryShort,
        };
      }
      if (!_.isEmpty(item.state)) {
        location = {
          ...location,
          state: item.state,
        };
      }
      if (!_.isEmpty(item.stateShort)) {
        location = {
          ...location,
          stateShort: item.stateShort,
        };
      }
      if (!_.isEmpty(item.city)) {
        location = {
          ...location,
          city: item.city,
        };
      }
      if (!_.isEmpty(item.coordinates)) {
        location = {
          ...location,
          coordinates: item.coordinates,
        };
      }
      return location;
    });
  }
  let mediaFiltered = {};
  const portList = _.filter(modal.portfolioPhotos, (photo) => {
    return !_.isEmpty(photo.image);
  });
  const videoList = _.filter(modal.extraVideo, (video) => {
    return !_.isEmpty(video.video);
  });
  if (!_.isEmpty(portList)) {
    mediaFiltered = {
      ...mediaFiltered,
      portfolioPhoto: portList,
    };
  }
  if (!_.isEmpty(videoList)) {
    mediaFiltered = {
      ...mediaFiltered,
      additionalVideo: videoList,
    };
  }
  if (modal.logo.image) {
    mediaFiltered = {
      ...mediaFiltered,
      logo: modal.logo,
    };
  }
  if (modal.featuredPhoto.image) {
    mediaFiltered = {
      ...mediaFiltered,
      featuredPhoto: modal.featuredPhoto,
    };
  }
  if (modal.portraitPhoto.image) {
    mediaFiltered = {
      ...mediaFiltered,
      portraitPhoto: modal.portraitPhoto,
    };
  }
  if (modal.featuredVideo.video || modal.featuredVideo.url) {
    mediaFiltered = {
      ...mediaFiltered,
      featuredVideo: modal.featuredVideo,
    };
  }
  let introduction = {
    title: modal.title,
    name: modal.name,
    description: modal.aboutMe,
    reason: modal.why,
    isRelocated: modal.willingToRelocate,
    opportunityStatus: modal.opportunityStatus,
    showOpportunityStatus: modal.showOpportunityStatus,
    relocateTo: modal.relocateList || [],
  } as any;
  introduction = {
    ...introduction,
    yearOfExperience: modal.experience !== -1 ? Number(modal.experience) : null,
  };
  const categories = _.filter(modal.categories, (item) => {
    return item.id !== -1;
  });
  const newCateList = _.map(categories, (item) => {
    let newItem = {
      name: item.name === "Other" ? item.otherName : item.name,
    } as any;
    if (item.id > 0) {
      newItem = {
        ...newItem,
        optionId: item.id,
      };
    }
    if (item.licenseNumber) {
      newItem = {
        ...newItem,
        licenseNumber: item.licenseNumber,
      };
    }
    if (item.stateId) {
      newItem = {
        ...newItem,
        stateId: item.stateId,
      };
    }
    return newItem;
  });
  let educations = _.map(modal.educations, (item) => {
    let newProgram = {
      name: item.program?.name,
    } as any;
    if (item.program?.id) {
      newProgram = {
        ...newProgram,
        optionId: item.program.id,
      };
    }
    let newEdu = {
      uuid: item.uuid || uuidv4(),
      schoolName: item.schoolName || "",
      program: newProgram,
      graduationDate: item.graduationDate
        ? moment(item.graduationDate).format("YYYY-MM-DD")
        : null,
    } as any;
    if (item.schoolId) {
      newEdu = {
        ...newEdu,
        schoolId: item.schoolId,
      };
    }
    newEdu = {
      ...newEdu,
      gpa: item.gpa ? Number(item.gpa) : null,
    };
    newEdu = {
      ...newEdu,
      attendance: item.attendance ? Number(item.attendance) : null,
    };
    newEdu = {
      ...newEdu,
      hasWorkPermit: item.hasWorkPermit || "no",
      workPermitHour: item.workPermitHour ? Number(item.workPermitHour) : null,
    };
    return newEdu;
  });
  educations = _.filter(educations, (item) => {
    return item.schoolName;
  });
  let request = {
    introduction: introduction,
    bookingUrl: modal.bookingUrl,
    pronouns: modal.pronouns,
    media: mediaFiltered,
    socialMedia: {
      instagram:
        _.find(modal.socialMediaList, { label: "instagram" })?.value || null,
      youtube:
        _.find(modal.socialMediaList, { label: "youtube" })?.value || null,
      facebook:
        _.find(modal.socialMediaList, { label: "facebook" })?.value || null,
      tiktok: _.find(modal.socialMediaList, { label: "tiktok" })?.value || null,
    },
    achievements: modal.awardList,
    detail: {
      categories: newCateList,
      locations: requestLocation,
    },
    additionalInformation: {
      preferredBrands:
        _.map(modal.preferredBrands, (item) => {
          let newItem = {
            name: item.name,
          } as any;
          if (item.id) {
            newItem = {
              ...newItem,
              optionId: item.id,
            };
          }
          return newItem;
        }) || [],
      specialities:
        _.map(modal.specialities, (item) => {
          let newItem = {
            name: item.name,
          } as any;
          if (item.id) {
            newItem = {
              ...newItem,
              optionId: item.id,
            };
          }
          return newItem;
        }) || [],
      softwares:
        _.map(modal.softwares, (item) => {
          let newItem = {
            name: item.name,
          } as any;
          if (item.id) {
            newItem = {
              ...newItem,
              optionId: item.id,
            };
          }
          return newItem;
        }) || [],
      paymentModeId: modal.payment.id || 0,
      KPI: modal.kpi || {},
    },
    employments:
      _.map(modal.employment, (item) => {
        let jobCategory = "";
        if (!_.isEmpty(item.jobCategory)) {
          const mapped = _.map(item.jobCategory, (item) => {
            return item.name || "";
          });
          jobCategory = mapped.join(",");
        }
        let position = "";
        if (!_.isEmpty(item.position)) {
          const mapped = _.map(item.position, (item) => {
            return item.name || "";
          });
          position = mapped.join(",");
        }
        return {
          uuid: item.uuid || uuidv4(),
          title: item.title,
          jobCategory: jobCategory,
          company: item.company,
          position: position,
          startDate: item.startDate
            ? moment(item.startDate).format("YYYY-MM-DD")
            : null,
          endDate: item.endDate
            ? moment(item.endDate).format("YYYY-MM-DD")
            : null,
          isCurrentWork: item.isCurrentWork,
          description: item.description,
        };
      }) || [],
    educations: educations || [],
  };

  return request;
};
