import React from "react";
import { useTable, useSortBy } from "react-table";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';

interface ReactTableProps {
  data: any;
  onMetricClick?: (metric: string) => void;
  metricHeaderName?: string;
  tooltip?: Array<{ name: string, tooltip: string }>;
}

const useStyles = makeStyles((theme) => ({
  bigTooltip: {
    fontSize: '16px',
    padding: theme.spacing(2),
  },
}));

const ReactTable: React.FC<ReactTableProps> = ({ data, onMetricClick, metricHeaderName, tooltip }) => {
  const classes = useStyles();

  const columns = React.useMemo(() => {
    if (data.length === 0) return [];

    return Object.keys(data[0]).map((key) => {
      if (key === 'metric') {
        return {
          Header: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {metricHeaderName || 'Metric'}
            </div>
          ),
          accessor: key,
          Cell: ({ value }: { value: string }) => (
            onMetricClick ? (
              <button style={{ color: 'black' }} onClick={() => onMetricClick(value)}>
                {value}
              </button>
            ) : (
              <div>{value}</div>
            )
          ),
        };
      } else {
        return {
          Header: key.charAt(0).toUpperCase() + key.slice(1),
          accessor: key,
          Cell: ({ value }: { value: any }) => (
            <div dangerouslySetInnerHTML={{ __html: value }} />
          ),
        };
      }
    });
  }, [data, onMetricClick, metricHeaderName, tooltip]);

  const fashionColors = [
    'rgba(170, 111, 190, 1)', // Purple
    'rgba(72, 61, 139, 0.7)', // Dark Blue
    'rgba(219, 112, 147, 0.7)',// Pink
    'rgba(147, 112, 219, 0.7)',// Medium Purple
    'rgba(100, 149, 237, 0.7)',// Cornflower Blue
    'rgba(255, 182, 193, 0.7)',// Light Pink
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  const camelCaseToSpaceSeparated = (str: string) => {
    return str.replace(/([A-Z0-9])/g, ' $1').replace(/^./, (char) => char.toUpperCase());
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <table {...getTableProps()} style={{ border: "solid 1px blue", width: "100%", marginTop: "20px" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, colIndex) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    padding: "5px",
                    borderBottom: "solid 3px black",
                    background: fashionColors[colIndex % fashionColors.length],
                    color: "white",
                    fontWeight: "bold",
                    position: colIndex === 0 ? 'sticky' : 'static',
                    left: colIndex === 0 ? 0 : 'auto',
                    zIndex: colIndex === 0 ? 1 : 'auto',
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "10px",
                      border: "solid 1px gray",
                      background: fashionColors[cellIndex % fashionColors.length],
                      color: "white",
                      position: cellIndex === 0 ? 'sticky' : 'static',
                      left: cellIndex === 0 ? 0 : 'auto',
                      zIndex: cellIndex === 0 ? 1 : 'auto',
                    }}
                  >
                    {cell.render("Cell")}
                    {cellIndex === 0 && tooltip && (
                      <>
                        <Tooltip
                          title={
                            tooltip.find(
                              t => camelCaseToSpaceSeparated(t.name) === cell.value
                            )?.tooltip || "No tooltip available"
                          }
                          classes={{ tooltip: classes.bigTooltip }}
                        >
                          <IconButton size="small" style={{ marginLeft: 5 }}>
                            <HelpIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReactTable;
